import { Injectable } from "@angular/core";
import {
  Actions,
  Effect,
  ofType,
} from "@ngrx/effects";
import {
  map,
  mergeMap,
  catchError,
  take,
} from "rxjs/operators";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Store } from "@ngrx/store";

import {
  LeagueActionTypes,
  GetLeagueById,
  GetLeagueByIdSuccess,
  GetLeagueByIdError,
} from "./league.actions";
import { RootState } from "..";
import { LeaguesV2Service } from "@services/v2/leagues/leagues.v2.service";

@Injectable()
export class LeagueEffects {
  @Effect()
  public league$ = this._actions$.pipe(
    ofType<GetLeagueById>(LeagueActionTypes.GET_LEAGUE_BY_ID),
    map((action) => action.payload),
    mergeMap((payload) => this._store.select("user", "currentUser").pipe(
      take(1),
      map((currentUser) => (currentUser !== null)),
      mergeMap((isAuth) => {
        if (isAuth) {
          return this._leagueV2Service.getLeagueDetailsAuthenticated(payload);
        } else {
          return this._leagueV2Service.getLeagueDetailsUnauthenticatede(payload);
        }
      })
    )),
    mergeMap((league) => {
      if (league) {
        return of(new GetLeagueByIdSuccess(league));
      } else {
        this._toastr.error("The league could not be retrieved", "Error");
        return of(new GetLeagueByIdError());
      }
    }),
    catchError(() => {
      this._toastr.error("The league could not be retrieved", "Error");
      return of(new GetLeagueByIdError());
    })
  );

  constructor(
    private _actions$: Actions,
    private _leagueV2Service: LeaguesV2Service,
    private _toastr: ToastrService,
    private _store: Store<RootState>
  ) { }
}
