/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { LeagueState } from "./league.types";
import { LeagueActions, LeagueActionTypes } from "./league.actions";

const initialState: LeagueState = {
  league: null,
  leagueEnrollmentStatus: null,
  fetchingAllLeagues: false,
  fetchingAllLeaguesError: false,
  fetchingLeagueById: false,
  fetchingLeagueByIdError: false,
};

export function leagueReducer(
  state = initialState,
  action: LeagueActions
): LeagueState {
  switch (action.type) {
    case LeagueActionTypes.GET_LEAGUE_BY_ID:
      return {
        ...state,
        fetchingLeagueById: true,
        fetchingLeagueByIdError: false,
        league: null,
      };
    case LeagueActionTypes.GET_LEAGUE_BY_ID_ERROR:
      return {
        ...state,
        fetchingLeagueById: false,
        fetchingLeagueByIdError: true,
        league: null,
      };
    case LeagueActionTypes.GET_LEAGUE_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingLeagueById: false,
        fetchingLeagueByIdError: false,
        league: action.payload,
      };
    case LeagueActionTypes.UPDATE_LEAGUE_BRACKET_DATA:
      return {
        ...state,
        league: {
          ...state.league,
          brackets: action.payload,
        },
      };
    case LeagueActionTypes.UPDATE_LEAGUE_TEAM_DATA:
      return {
        ...state,
        league: {
          ...state.league,
          teams: action.payload,
        },
      };
    case LeagueActionTypes.UPDATE_LEAGUE_ENROLLMENT_DATA:
      return {
        ...state,
        leagueEnrollmentStatus: action.payload,
      };
    default:
      return state;
  }
}
