export enum FirestoreCollections {
  ANNOUNCEMENTS = "announcements",
  CHATROOMS = "chatrooms",
  NOTIFICATIONS = "notifications",
  SCRIMMAGE_CHATROOMS = "scrimmage-chatrooms",
  SERIES_MATCHUP_STREAMDATA = "series-matchup-streams",
  USER_CHATS = "user-chats",
  TOURNAMENT_CHATROOMS = "tournament-chatrooms",
  TOURNAMENT_STREAMS = "tournament-streams",
  QUEUE_SESSION_STREAMS = "tournament-queue-session-streams",
  QUEUE_SESSION_STREAM_CHECKINS = "tournament-queue-session-checkins",
}

export enum SeriesMatchupStreamCollections {
  BATTLE_ROYALE_GAMES = "battle-royale-matchup-games",
}

export enum TournamentStreamCollections {
  TOURNAMENT_CHECKINS = "tournament-checkins",
}
