import { Component, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  combineLatest,
  Observable,
  of,
} from "rxjs";
import {
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { NgxSmartModalService } from "ngx-smart-modal";

import { LeagueEndpointService } from "@services/league-endpoints.service";
import { Unsubscriber } from "@utils/unsubscriber";
import { modalOptions } from "@utils/modal-helpers";
import { TournamentCheckin } from "@apptypes/streaming-tournament.types";

import { RootState } from "src/app/reducers";
import {
  GenericLeagueDetails,
  LeagueBracketDetails,
  LeagueBracketStatus,
} from "src/app/reducers/leagues/league.types";
import { UserProfile, UserTeam } from "src/app/reducers/user/user.types";
// eslint-disable-next-line max-len
import { BattleRoyaleCheckinModalComponent, QuickPlayCheckinInfo } from "../battle-royale-checkin-modal/battle-royale-checkin-modal.component";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { ParticipantListModalComponent, ParticipantListTeamModalData } from "../participant-list-modal/participant-list-modal.component";
import { LeaguesV2Service } from "@services/v2/leagues/leagues.v2.service";


const QuickPlayCheckinStatusLabel: { [key in LeagueBracketStatus]: string } = {
  [LeagueBracketStatus.PENDING]: "Tournament has been created. Check-Ins are Closed",
  [LeagueBracketStatus.IN_PROGRESS]: "Tournament is In Progress. Check-Ins are Closed",
  [LeagueBracketStatus.COMPLETED]: "Tournament is Completed",
  [LeagueBracketStatus.CANCELLED]: "Tournament is Cancelled",
};

const QuickPlayCheckinStatusDisabledMessages: { [key in LeagueBracketStatus]: string } = {
  [LeagueBracketStatus.CANCELLED]: "The tournament has been cancelled",
  [LeagueBracketStatus.COMPLETED]: "The tournament has been played to completion",
  [LeagueBracketStatus.IN_PROGRESS]: "The tournament has started and players can no longer check-in",
  [LeagueBracketStatus.PENDING]: "The tournament is waiting seeding",
};

@Component({
  selector: "app-quickplay-checkin-panel",
  templateUrl: "./quickplay-checkin-panel.component.html",
  styleUrls: ["./quickplay-checkin-panel.component.scss"],
})
export class QuickplayCheckinPanelComponent implements OnDestroy {
  public streamingData$: Observable<LeagueBracketDetails | null> = of(null);
  public streamingCheckins$: Observable<TournamentCheckin[] | null> = of(null);
  public activeTeam$: Observable<UserTeam | null> = of(null);
  public user$: Observable<UserProfile | null> = of(null);
  public league$: Observable<GenericLeagueDetails | null> = of(null);

  public esport: gamePlatforms | null = null;

  public statusLabels = QuickPlayCheckinStatusLabel;
  public disabledMessages = QuickPlayCheckinStatusDisabledMessages;

  private _unsub = new Unsubscriber();

  constructor(
    private _store: Store<RootState>,
    private _leaguesService: LeagueEndpointService,
    private _leagueV2Service: LeaguesV2Service,
    private _modalService: NgxSmartModalService
  ) {
    this.user$ = this._store.select("user", "currentUser").pipe(
      first()
    );

    this.league$ = this._store.select("leagues", "league").pipe(
      tap(({ esport }) => {
        this.esport = esport;
      }),
      takeUntil(this._unsub.unsubEvent)
    );

    const streamReferenceId$: Observable<string | null> = this.league$.pipe(
      switchMap((league) => {
        const leagueId = league.id;
        return this._leagueV2Service.getQuickplayTournaments(leagueId);
      }),
      map((brackets: LeagueBracketDetails[] = []) => brackets[0]?.streamReferenceId ?? null),
      takeUntil(this._unsub.unsubEvent)
    );

    this.activeTeam$ = combineLatest([this.user$, this.league$]).pipe(
      filter(([user, league]) => !!user && !!league),
      map(([user, league]) => {
        const leagueTeamIds = league.teams.map(team => team.id);
        return user.teams.find(({ id }) => leagueTeamIds.indexOf(id) !== -1);
      }),
      first()
    );

    this.streamingData$ = streamReferenceId$.pipe(
      switchMap((refId) => {
        if (refId) {
          return this._leaguesService.getStreamingTournamentData(refId);
        }
        return of(null);
      }),
      takeUntil(this._unsub.unsubEvent)
    );

    this.streamingCheckins$ = streamReferenceId$.pipe(
      switchMap((refId) => {
        if (refId) {
          return this._leaguesService.getStreamingCheckinData(refId);
        }
        return of(null);
      }),
      takeUntil(this._unsub.unsubEvent)
    );
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public openCheckinModal(tournamentId: string | number, teamId: string | number): void {
    const checkinInfo: QuickPlayCheckinInfo = {
      tournamentId,
      teamId,
      esport: this.esport,
      type: "tournament",
    };

    this._modalService.create(
      BattleRoyaleCheckinModalComponent.MODAL_NAME,
      BattleRoyaleCheckinModalComponent,
      modalOptions
    ).setData(checkinInfo).open();
  }

  public getStatusDisplayLabel({ matchupMapping, status }: LeagueBracketDetails): string {
    if (matchupMapping && matchupMapping.length === 0 && status === LeagueBracketStatus.PENDING) {
      return "Tournament is Accepting Check-Ins";
    };

    return this.statusLabels[status];
  }

  public isCheckinEnabled({ matchupMapping, status }: LeagueBracketDetails): boolean {
    return matchupMapping && matchupMapping.length === 0 && status === LeagueBracketStatus.PENDING;
  }

  public getCheckinDisabledMessage({ matchupMapping, status }: LeagueBracketDetails): string {
    if (status === LeagueBracketStatus.PENDING) {
      return matchupMapping.length === 0 ? "" : "The tournament has been finalized and user's can no longer check-in";
    }

    return this.disabledMessages[status];
  }

  public isUserCheckedIn(user: UserProfile | null, checkins: TournamentCheckin[] | null): boolean {
    if (!user || !checkins) {
      return false;
    }

    return !!checkins.find((checkin) => checkin.userId === `${user.id}`);
  }

  public viewCheckedInTeams(checkins: TournamentCheckin[], league: GenericLeagueDetails): void {
    const mapTeamToParticipantTeam = ({ teamId, esportCredentialSnapshot }: TournamentCheckin): ParticipantListTeamModalData | null => {
      const leagueTeam = league.teams.find(team => `${team.id}` === teamId);

      if (!leagueTeam) {
        return null;
      }

      const { title: teamName, logoUrl: teamLogoUrl } = leagueTeam;

      return {
        teamId,
        teamName,
        teamLogoUrl,
        subtext: esportCredentialSnapshot,
      };
    };

    const teamList = checkins.map(mapTeamToParticipantTeam).filter(team => !!team);

    this._modalService.create(
      ParticipantListModalComponent.MODAL_ID,
      ParticipantListModalComponent,
      modalOptions
    ).setData(teamList).open();
  }
}
