import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { Unsubscriber } from "@utils/unsubscriber";
import { ChatData } from "@apptypes/chat/chat-data.interface";
import { TournamentCommentServiceProvider } from "@providers/comment-service/tournament-comment-service.provider";

import { RootState } from "src/app/reducers";
import { GenericLeagueDetails } from "src/app/reducers/leagues/league.types";
import { ChatResourceTypes } from "src/app/enums/chat-resource-types";
import { ChatBlockTeam } from "../split-admin-chat-block/split-admin-chat-block.component";

@Component({
  selector: "app-league-tournament-chat-block",
  templateUrl: "./league-tournament-chat-block.component.html",
  styleUrls: ["./league-tournament-chat-block.component.scss"],
  providers: [TournamentCommentServiceProvider],
})
export class LeagueTournamentChatBlockComponent implements OnInit, OnDestroy {
  public league$: Observable<GenericLeagueDetails | null> = of(null);
  public chatData$: Observable<ChatData | null> = of(null);
  public referenceTypes = ChatResourceTypes;

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>) {}

  public ngOnInit(): void {
    this.league$ = this._store.select("leagues", "league").pipe(
      takeUntil(this._unsub.unsubEvent)
    );

    this.chatData$ = this.league$.pipe(
      map((league) => {
        //Assuming Quickplays only have 1 tournament for now
        if(league.brackets && league.brackets[0]){
          const tournament = league.brackets[0];
          return {
            railsID: tournament.id,
            firestoreDocID: tournament.chatReferenceId ?? "",
            referenceType: ChatResourceTypes.TOURNAMENT,
          };
        }

        return null;
      })
    );
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public mapChatBlockTeams({ teams }: GenericLeagueDetails): ChatBlockTeam[] {
    if(!teams){
      return [];
    }

    return teams.map(({ id, userIds }) => ({
      id,
      players: userIds.map(userId => ({
        id: userId,
      })),
    }));
  }
}
