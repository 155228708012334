import {
  compareAsc,
  compareDesc,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  formatISO,
  fromUnixTime,
  getMonth,
  getUnixTime,
  getYear,
  isAfter,
  isBefore,
  parse,
  parseISO,
} from "date-fns";

import { ISOString } from "@apptypes/date.types";

import { Logger } from "./logger";
import { YearMonthFilter } from "../components/league-matches-block/league-matches-block.component";

export const isoToShort = (isoString: ISOString): string => {
  try {
    const date = parseISO(isoString);
    return format(date, "MM/dd/yyyy");
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const unixTimeToLong = (unixTime: number): string => {
  try {
    const unixRealDate = fromUnixTime(unixTime);
    return format(unixRealDate, "MMM do");
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const unixTimeToLongWithYear = (unixTime: number): string => {
  try {
    const unixDate = fromUnixTime(unixTime);
    return format(unixDate, "MMM do yyyy");
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const unixTimeToFull = (unixTime: number): string => {
  try {
    const unixRealDate = fromUnixTime(unixTime);
    return format(unixRealDate, "MM/dd/yyyy");
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const dateFromUnixTime = (unixTime: number): Date =>
  fromUnixTime(unixTime);

export const getCurrentUnixTime = (): number => {
  const date = getUnixTime(new Date());
  return date;
};

export const getExtendedDate = (unixTime: number): string => {
  try {
    const date = fromUnixTime(unixTime);
    return format(date, "EEEE MMMM do, h:mmaaa");
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const isoStringToMedDateTime = (isoTime: ISOString): string => {
  try {
    const date = parseISO(isoTime);
    return format(date, "EEEE MMMM do, h:mmaaa");
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const unixTimeToFullWithTime = (unixTime: number): string => {
  try {
    const date = fromUnixTime(unixTime);
    return format(date, "MM/dd/yyyy,  h:mmaaa");
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const unixTimeToWeeklyTime = (unixTime: number): string => {
  try {
    const date = fromUnixTime(unixTime);
    return format(date, "EEEE's' 'at' haaa");
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const unixTimeToShortWithTime = (unixTime: number): string => {
  try {
    const date = fromUnixTime(unixTime);
    return format(date, "MMM do '@' ha");
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const isoStringToShortWithTime = (isoDate: string): string => {
  try {
    const date = parseISO(isoDate);
    return format(date, "MMM do '@' ha");
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const getUnixTimeFromDOMDateString = (date: string): number =>
  getUnixTime(parse(date, "yyyy-MM-dd", new Date()));

export const getTwentFourHourTimeFromUnixTime = (unixTime: number): string => {
  try {
    const date = fromUnixTime(unixTime);
    return format(date, "HH:mm");
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const getHour = (unixTime: number): string => {
  try {
    const date = fromUnixTime(unixTime);
    return format(date, "ha");
  }
  catch (e) {
    Logger.error(e);
    return "";
  }
};

export const getHourFromISOString = (isoDate: ISOString): string => {
  try {
    const date = parseISO(isoDate);
    return format(date, "ha");
  }
  catch (e) {
    Logger.error(e);
    return "";
  }
};

export const getDayOfWeek = (unixTime: number): string => {
  try {
    const date = fromUnixTime(unixTime);
    return format(date, "EEEE");
  }
  catch (e) {
    Logger.error(e);
    return "";
  }
};

export const getDayOfWeekFromISOString = (isoDate: ISOString): string => {
  try {
    const date = parseISO(isoDate);
    return format(date, "EEEE");
  }
  catch (e) {
    Logger.error(e);
    return "";
  }
};

export const getMonthDateFromISOString = (isoDate: ISOString): string => {
  try {
    const date = parseISO(isoDate);
    return format(date, "MMMM do");
  }
  catch (e) {
    Logger.error(e);
    return "";
  }
};

/**
 * Returns difference between given ISO Date and current time, by specified unit.
 * Defaults to `hours` if unit not specified
 * Negative values mean the date is before current time, positive values mean game is after current time
 *
 * @author Alex Muench
 */
export const differenceFromNow = (ISOdateToCheck: ISOString, unit: "min" | "hours" | "days" = "hours"): number => {
  try {
    const parsedDate = parseISO(ISOdateToCheck);
    const now = new Date();

    if (unit === "days") {
      return differenceInDays(parsedDate, now);
    } else if (unit === "min") {
      return differenceInMinutes(parsedDate, now);
    } else {
      return differenceInHours(parsedDate, now);
    }
  } catch (e) {
    Logger.error(e);
    throw new Error("Could not process dates when cacluating difference.  This is usually caused by a bad ISO String input value");
  }
};

export const isoStringToUnixTime = (date: ISOString): number => {
  try {
    return getUnixTime(parseISO(date));
  }
  catch (e) {
    Logger.error(e);
    return 0;
  }
};

export const isISODateInFuture = (isoDate: ISOString): boolean => {
  try {
    const now = new Date();
    const date = parseISO(isoDate);
    return isAfter(date, now);
  }
  catch (e) {
    Logger.error(e);
    return false;
  }
};

export const isISODateInPast = (isoDate: ISOString): boolean => {
  try {
    const now = new Date();
    const date = parseISO(isoDate);
    return isBefore(date, now);
  }
  catch (e) {
    Logger.error(e);
    return false;
  }
};

export const unixTimeToIsoString = (unixTime: number): string => {
  try {
    const date = fromUnixTime(unixTime);
    return formatISO(date);
  }
  catch (e) {
    Logger.error(e);
    return "";
  }
};

export const compareISODatesAsc = (isoDateA: ISOString, isoDateB: ISOString): number => {
  try {
    const dateA = parseISO(isoDateA);
    const dateB = parseISO(isoDateB);
    return compareAsc(dateA, dateB);
  }
  catch (e) {
    Logger.error(e);
    return 0;
  }
};

export const compareISODatesDesc = (isoDateA: ISOString, isoDateB: ISOString): number => {
  try {
    const dateA = parseISO(isoDateA);
    const dateB = parseISO(isoDateB);
    return compareDesc(dateA, dateB);
  }
  catch (e) {
    Logger.error(e);
    return 0;
  }
};

export const convertDateToYearMonth = (isoDate: string): YearMonthFilter | null => {
  try {
    const date = parseISO(isoDate);
    const year = getYear(date);
    const month = getMonth(date);
    return {
      year,
      month,
    };
  }
  catch (e) {
    Logger.error(e);
    return null;
  }
};

/**
 * Pads single digit number to time format (e.g. 1 to "01") so it can be used in clocks and timers
 *
 * @param timeVal raw time value
 * @return returns padded time value
 */
export const padTimeValue = (timeVal: number): string => {
  const needsPad = `${timeVal}`.length === 1;
  return needsPad ? `0${timeVal}` : `${timeVal}`;
};
