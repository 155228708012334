import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  catchError,
  filter,
  map,
  mergeMap,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { NgxSmartModalService } from "ngx-smart-modal";
import { combineLatest, of } from "rxjs";

import { LeagueEndpointService } from "@services/league-endpoints.service";

import { RootState } from "src/app/reducers";
import { PersonalUserProfile, UserProfile } from "src/app/reducers/user/user.types";
import { Logger } from "../../util/logger";
import {
  GenericLeagueDetails,
  LeagueRegistrationInformation,
  LeagueTeam,
  RegistrationTeamInfo,
} from "src/app/reducers/leagues/league.types";
import { DisabledMessages, LeagueRegistrationDisabledMessages } from "src/app/enums/registration-types.enum";
import { isISODateInPast } from "src/app/util/date-utils";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { JoinLeagueModalComponent } from "../join-league-modal/join-league-modal.component";
import { modalOptions } from "src/app/util/modal-helpers";
import { CustomerSupportService } from "@services/customer-support/customer-support.service";
import { LeaguesV2Service } from "@services/v2/leagues/leagues.v2.service";
import { UpdateLeagueEnrollmentStatus } from "src/app/reducers/leagues/league.actions";

@Component({
  selector: "app-join-league-button",
  templateUrl: "./join-league-button.component.html",
  styleUrls: ["./join-league-button.component.scss"],
})
export class JoinLeagueButtonComponent implements OnInit, OnDestroy {
  public toolTipMessage = "";
  public leagueRegistrationInformation: LeagueRegistrationInformation;
  public isLoading = true;
  public isLeagueFull = false;
  public isLeagueRegistrationClosed = false;
  public userInLeague = false;
  public leagueHasError = false;
  public userNeedsSeasonPass = false;

  private _unsub = new Unsubscriber();
  private _leagueId: number | string;
  private _allUserTeamsWithStatus: RegistrationTeamInfo[] = [];

  constructor(
    private _modal: NgxSmartModalService,
    private _zendeskService: CustomerSupportService,
    private _store: Store<RootState>,
    private _leagueEndpointService: LeagueEndpointService,
    private _leagueV2Service: LeaguesV2Service
  ) { }

  public ngOnInit(): void {
    //Perhaps consider adding timeout?
    this._store
      .select("leagues", "league")
      .pipe(
        withLatestFrom(this._store.select("user", "currentUser")),
        filter(([league, user]) => !!league && !!user),
        //TODO: Extract this mergemap into a util with generics and typing
        //it's going to be a little tough
        mergeMap(([league, user]) => {
          this._leagueId = league.id;
          return combineLatest([
            of(league),
            of(user),
            this._leagueEndpointService.getLeagueRegistrationInformationById(`${league.id}`),
          ]);
        }),
        filter(([
          , , info,
        ]) => !!info),
        tap(([league, user]) => this._checkIfUserNeedsSeasonPass(user, league)),
        tap(([league, ,]) => this._setToolTipStatus(league)),
        map(([
          league,
          user,
          leagueRegInfo,
        ]) => this._populateRegistrationInformation(league, user, leagueRegInfo, league.teams)),
        catchError((err) => {
          Logger.error(err);
          return of(null);
        }),
        takeUntil(this._unsub.unsubEvent)
      )
      .subscribe((leagueRegInfo: LeagueRegistrationInformation) => this._saveRegistrationInformation(leagueRegInfo));
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
    this._modal.resetModalData(JoinLeagueModalComponent.MODAL_ID);
  }

  /**
   * Opens the Join League Modal and preloads its data
   * Also closes the zendesk widget for the time being
   *
   * @author Christian Tweed
   */
  public openDialogue() {
    this._modal.create(
      JoinLeagueModalComponent.MODAL_ID,
      JoinLeagueModalComponent,
      modalOptions).setData(this.leagueRegistrationInformation).open();
  }

  public get cantJoinLeagueCopy(): string {
    if (this.userInLeague) {
      return "You're already enrolled in this league!";
    } else if (this.isLeagueFull) {
      return "This league is full";
    } else if (this.isLeagueRegistrationClosed) {
      return "This league is closed for registration";
    } else if (this.userNeedsSeasonPass) {
      return "You need a valid season pass to join this league, click to find out more";
    } else {
      return "There was an error loading this league, please refresh and try again.  If this persists contact support";
    }
  }

  public get useGetPassButton(): boolean {
    if (this.userNeedsSeasonPass) {
      return (!this.userInLeague && !this.isLeagueFull && !this.isLeagueRegistrationClosed);
    }

    return false;
  }

  public openSupportModal(): void {
    this._zendeskService.openCustomerSupport();
  }

  /**
   * Saves the league registration information and declares loading to be stopped. If
   * there was an error it updates the tooltip appropriately
   *
   * @param leagueRegInfo is the league registration information (or null if there was an error)
   */
  private _saveRegistrationInformation(leagueRegInfo: LeagueRegistrationInformation): void {
    if (leagueRegInfo) {
      this._allUserTeamsWithStatus.map((team) => {
        if (team.ineligibleReason === DisabledMessages.ALREADY_IN_LEAGUE) {
          this.userInLeague = true;
          this._store.dispatch(new UpdateLeagueEnrollmentStatus({
            id: this._leagueId,
            userIsInLeague: true,
            teamId: team.id,
          }));
          this.toolTipMessage = LeagueRegistrationDisabledMessages.ALREADY_IN_LEAGUE;
        }
      });
      this.leagueRegistrationInformation = leagueRegInfo;
    } else {
      this.leagueHasError = true;
      this.toolTipMessage = LeagueRegistrationDisabledMessages.ERROR;
      this.leagueRegistrationInformation = null;
    }
    this.isLoading = false;
  }

  /**
   * Takes the league, user, and registration information and adds the eligible teams and config to the registration information
   *
   * @param league is the league details
   * @param user is the current user profile
   * @param info is the league registration information
   * @author Christian Tweed
   */
  private _populateRegistrationInformation(
    league: GenericLeagueDetails,
    user: UserProfile,
    info: LeagueRegistrationInformation,
    teams: LeagueTeam[]
  ): LeagueRegistrationInformation {
    const teamIds = teams.map((team) => team.id);
    info.teams = this._loadEligibleTeams(user, info.minimumTeamSize, info.maximumTeamSize, teamIds);
    info.registrationConfig = {
      team_registration_type: league.teamRegistrationType,
    };
    //TODO: Get rid of when api data is available
    info.isSeasonPassLeague = league.organizationSeasonPassIds && league.organizationSeasonPassIds.length > 0;
    return info;
  }

  /**
   * Examines the league and the registration information to check
   * if the league is open or has spots available
   *
   * @param league is the league details
   * @author Christian Tweed
   */
  private _setToolTipStatus(league: GenericLeagueDetails): void {
    if (league.maxEntrants <= league.currentEntrants) {
      this.isLeagueRegistrationClosed = true;
      this.toolTipMessage = LeagueRegistrationDisabledMessages.LEAGUE_STARTED;
    }

    if (isISODateInPast(league.registrationEndDate)) {
      this.isLeagueRegistrationClosed = true;
      this.toolTipMessage = LeagueRegistrationDisabledMessages.LEAGUE_STARTED;
    }
  }

  /**
   * Filters through all the user's teams for teams that are eligible to join the league
   *
   * @param user is the current user profile
   * @param minTeamSize is the minimum team size
   * @param maxTeamSize is the maximum team size
   * @param leagueTeamIds is the series of team ids already in the league
   * @author Christian Tweed
   */
  private _loadEligibleTeams(user: UserProfile, minTeamSize: number, maxTeamSize: number, leagueTeamIds: number[]): RegistrationTeamInfo[] {
    const userTeamsWithStatus = user.teams
      .map((team) => {
        let eligible = true;
        let reason = null;

        if (!!minTeamSize && team.playerCount < minTeamSize) {
          eligible = false;
          reason = DisabledMessages.TOO_FEW_PLAYERS;
        }

        if (!!maxTeamSize && team.playerCount > maxTeamSize) {
          eligible = false;
          reason = DisabledMessages.TOO_MANY_PLAYERS;
        }

        if (leagueTeamIds.includes(team.id)) {
          eligible = false;
          reason = DisabledMessages.ALREADY_IN_LEAGUE;
        }

        return {
          id: team.id.toString(),
          captain: team.captain,
          isCaptain: team.isCaptain,
          playerCount: team.playerCount,
          logoUrl: team.logoUrl,
          title: team.title,
          isEligible: eligible,
          ineligibleReason: reason,
          teamType: team.teamType,
        };
      });
    // Stash all teams list for league eligibility
    this._allUserTeamsWithStatus = userTeamsWithStatus;
    return userTeamsWithStatus.filter((team) => team.isCaptain === true);
  }

  private _checkIfUserNeedsSeasonPass(user: PersonalUserProfile, league: GenericLeagueDetails): void {
    this.userNeedsSeasonPass = this._leagueUsesSeasonPasses(league) &&
      !user.gamePasses.find(pass => league.organizationSeasonPassIds.includes(pass.seasonPassId));

    if (this.userNeedsSeasonPass) {
      this.toolTipMessage = LeagueRegistrationDisabledMessages.NEED_SEASON_PASS;
    }

    return;
  }

  private _leagueUsesSeasonPasses(league: GenericLeagueDetails): boolean {
    const { organizationSeasonPassIds } = league;
    return organizationSeasonPassIds && organizationSeasonPassIds.length > 0;
  }
}
