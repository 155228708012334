import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";

import { CommentValidityErrors } from "../comment-validator/comment-validator.types";
import { CommentService } from "../comment.abstract.service";
import { RailsScrimmageComment } from "../comment.api.types";
import { ChatData } from "@apptypes/chat/chat-data.interface";

/**
 * A comment service to post scrimmage comments to the rails application
 *
 * @author Christian Tweed
 */
@Injectable()
export class RailsScrimmageCommentService extends CommentService {

  public postComment({ railsID: scrimmageId }: ChatData, authorId: string, message: string): Observable<unknown> {
    const url = `${environment.apiBase}/api/v2/scrimmages/${scrimmageId}/comment`;

    const options = {
      headers: basicAuthHeader(),
    };

    const messageError: false | CommentValidityErrors = this._commentValidator.isMessageInvalid(message);
    if (messageError) {
      this._logMessageError(messageError);
      return of(null);
    }

    const body = this._formatChatMessage(scrimmageId, authorId, message);

    return this._http.post(url, body, options);
  }

  protected _formatChatMessage(scrimmage_id: string, user_id: string, body: string): RailsScrimmageComment {
    return {
      comment: {
        body,
        user_id,
        scrimmage_id,
      },
    };
  }
}
