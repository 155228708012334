import { Component, Input } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";

import { SeriesMatchupV2, MatchReport } from "src/app/reducers/matches/matches.types";
import { SelfReportModalComponent } from "./self-report-modal.component";
import { gamePlatforms } from "src/app/enums/game-platforms.enum";
import { modalOptions } from "src/app/util/modal-helpers";
import { TeamTypes } from "src/app/reducers/teams/teams.types";
import { ActivatedRoute } from "@angular/router";
import { MatchesService } from "@services/matches/matches.service";
import { take } from "rxjs/operators";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { MatchHasResultsModalComponent } from "../match-has-results-modal/match-has-results-modal.component";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/reducers";
import { GetSeriesMatchupByIdSuccess } from "src/app/reducers/matches/matches.actions";

@Component({
  selector: "app-self-report-button",
  templateUrl: "./self-report-button.component.html",
  styleUrls: ["./self-report-modal.component.scss"],
})
export class SelfReportButtonComponent {
  @Input() public seriesMatchup: SeriesMatchupV2;

  public buttonLoading = false;

  constructor(
    private _modalService: NgxSmartModalService,
    private _route: ActivatedRoute,
    private _matchesService: MatchesService,
    private _store: Store<RootState>
  ) { }

  public openModal() {
    this.buttonLoading = true;
    this._route.paramMap.pipe(take(1)).subscribe(async (params) => {
      const seriesMatchupId = params.get(ComponentRouteParams.SERIES_MATCHUP_ID);
      const seriesMatchupData = await this._matchesService.getSeriesMatchupV2ById(seriesMatchupId).toPromise();

      if (seriesMatchupData && !!seriesMatchupData.winnerId) {
        this._modalService.create(
          MatchHasResultsModalComponent.MODAL_NAME,
          MatchHasResultsModalComponent,
          modalOptions
        ).open();
        this._store.dispatch(new GetSeriesMatchupByIdSuccess(seriesMatchupData));
      } else {
        this._modalService.create(
          SelfReportModalComponent.MODAL_ID,
          SelfReportModalComponent,
          modalOptions
        ).setData(this._mapSeriesMatchupToMatchReport()).open();
      }
      this.buttonLoading = false;
    });
  }


  private _mapSeriesMatchupToMatchReport(): MatchReport {
    const matchReport: MatchReport = {
      seriesMatchupsId: this.seriesMatchup.id,
      teams: this.seriesMatchup.teams.map((team) => ({
        id: team.id,
        title: team.teamType === TeamTypes.BYE ? "BYE WEEK" : team.title,
        logoUrl: team.logoUrl,
      })),
      games: this.seriesMatchup.matches.map((match) => ({
        gameId: match.id,
        winnerId: match.winnerId,
      })),
      totalMatches: this.seriesMatchup.matches.length,
      esport: this.seriesMatchup.league ? this.seriesMatchup.esport : gamePlatforms.OTHER,
    };
    return matchReport;
  }
}
