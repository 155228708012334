export enum ChatResourceTypes {
  SERIES_MATCHUP = "series_matchup_id",
  SCRIMMAGE = "scrimmage_id",
  TOURNAMENT = "tournament_id",
}

export enum APIChatResourceTypes {
  SERIES_MATCHUP = "series_matchup",
  SCRIMMAGE = "scrimmage",
  TOURNAMENT = "tournament",
}

export const convertChatResourceToAPI = (referenceType: ChatResourceTypes): APIChatResourceTypes => {
  switch (referenceType) {
    case ChatResourceTypes.SERIES_MATCHUP:
      return APIChatResourceTypes.SERIES_MATCHUP;
    case ChatResourceTypes.SCRIMMAGE:
      return APIChatResourceTypes.SCRIMMAGE;
    case ChatResourceTypes.TOURNAMENT:
      return APIChatResourceTypes.TOURNAMENT;
    default:
      return null;
  }
};
