import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RedirectGuard implements CanActivate {

  constructor(private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    window.location.href = route.data.externalUrl;
    return true;
  }
}
