import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";

import { TeamsService } from "@services/teams.service";
import { ImageResizeService, ProcessedFile } from "@services/images/image-resize/image-resize.service";

import { profanityValidator } from "src/app/util/form-helpers";
import { teamRoutes } from "src/app/enums/routes/routePaths";
import { FileFormController } from "src/app/components/file-form-controller/file-form-controller.abstract";
import { ImageTypes } from "src/app/util/image-utils";
import { CreateTeamRequest } from "src/app/reducers/teams/teams.types";
import { Logger as logger } from "../../util/logger";

@Component({
  selector: "app-create-team-page",
  templateUrl: "./create-team-page.component.html",
  styleUrls: ["./create-team-page.component.scss"],
})
export class CreateTeamPageComponent extends FileFormController implements OnInit {
  public createTeamForm: FormGroup;
  public isLoading = false;
  public routes = teamRoutes;
  public serverError = false;

  private _imageSrc: string;

  constructor(private _titleService: Title, private _teamService: TeamsService, private _imgResizeService: ImageResizeService) {
    super(_imgResizeService);
    this.createTeamForm = new FormGroup({
      title: new FormControl("", [
        Validators.required,
        profanityValidator,
        Validators.maxLength(32),
        Validators.minLength(3),
      ]),
      description: new FormControl("", [profanityValidator]),
      logoUrl: new FormControl(),
      splitTeamFee: new FormControl(),
    });
    this._loadForm(this.createTeamForm);

    this._imageSrc = ImageTypes.TEAM_SQ;
  }

  public ngOnInit() {
    this._titleService.setTitle("GGLeagues | Create New Team");
  }

  public createTeam(): void {
    if (this._canProceed()) {
      const formValues = this.createTeamForm.value;
      const trimmedTitle = formValues.title.trim();
      if (trimmedTitle.length < 3) {
        this.createTeamForm.get("title").setValue(trimmedTitle);
        return;
      }
      const formObj: CreateTeamRequest = {
        title: formValues.title,
        split_team_fee: formValues.splitTeamFee ?? false,
        logo: this.imageFile?.file ?? null,
        description: formValues.description,
      };
      this._teamService.createTeam(formObj).subscribe(
        //Response
        () => {
          window.location.href = `/${teamRoutes.MY_TEAMS}`;
        },
        //Error
        (err) => {
          logger.error(err);
          this.serverError = true;
        }
      );
    }
  }

  public get image(): string {
    return this.imageFile?.dataURL ?? this._imageSrc;
  }

  public get isUsingDefaultImage(): boolean {
    return this.image === ImageTypes.TEAM_SQ;
  }

  public get updatedFileName(): string {
    const file: ProcessedFile = this.createTeamForm.get("loadedFile").value;
    return file?.file.name ?? "";
  }
}
