<ng-container *ngIf="league$ | async as league">
  <ng-container *ngIf="chatData$ | async as chatData">
    <app-split-admin-chat-block
      *ngIf="!!chatData.firestoreDocID"
      [chatData]="chatData"
      [teams]="mapChatBlockTeams(league)"
    >
    </app-split-admin-chat-block>
  </ng-container>
</ng-container>
