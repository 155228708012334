import {
  HostListener,
  Input,
  OnChanges,
} from "@angular/core";
import {
  AfterContentInit,
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
import { first, mergeMap } from "rxjs/operators";

import { LeaguesV2Service } from "@services/v2/leagues/leagues.v2.service";

import {
  LeagueBracketDetails,
  GenericLeagueBracket,
  LeagueTeam,
} from "src/app/reducers/leagues/league.types";

enum columnSizes {
  MOBILE = 2,
  TABLET = 4,
  DESKTOP = 6,
}

const EMPTY_PLAYIN_GAME: GenericLeagueBracket = {
  round: -1,
  winner: null,
  teamOne: -1,
  teamTwo: -1,
  matchMapper: -1,
  isPlayin: true,
};

@Component({
  selector: "app-bracket-controller",
  templateUrl: "./bracket-controller.component.html",
  styleUrls: ["./bracket-controller.component.scss"],
})
export class BracketControllerComponent implements OnInit, AfterContentInit, OnChanges {
  @Input() public tournament: LeagueBracketDetails | null = null;
  @Input() public useStream = false;
  @Input() public teamList: LeagueTeam[] = [];
  public isLoading = true;

  public bracketArray: Array<GenericLeagueBracket[]> = [];
  public currentBracketSlices: Array<GenericLeagueBracket[]> = [];
  public currentRoundIndex = 0;
  public indexMax: number;
  public maxRounds: number;
  public columnSize: columnSizes;

  public bracketList: LeagueBracketDetails[] = [];

  private _validTournament = false;

  constructor(
    private _leagueV2Service: LeaguesV2Service,
    private _activatedRoute: ActivatedRoute
  ) {}

  @HostListener("window:resize", ["$event"])
  public onResize() {
    if (window.innerWidth > 1000) {
      this.columnSize = columnSizes.DESKTOP;
    } else if (window.innerWidth <= 1000 && window.innerWidth >= 500) {
      this.columnSize = columnSizes.TABLET;
    } else {
      this.columnSize = columnSizes.MOBILE;
    }

    if (this._validTournament) {
      this.bracketArray = this._renderBrackets();
    }
  }

  public ngOnInit() {
    if (!this.useStream) {
      this._activatedRoute.paramMap.pipe(
        mergeMap((routeParams) => {
          const leagueId = routeParams.get("leagueid");
          return forkJoin(
            {
              brackets: this._leagueV2Service.getLeagueBrackets(leagueId),
              teams: this._leagueV2Service.getLeagueTeamList(leagueId),
            }
          );
        }),
        first()
      ).subscribe(
        (leagueFinalData) => {
          const { brackets, teams } = leagueFinalData;
          this.isLoading = false;
          const sortedBrackets = [...brackets];
          sortedBrackets.sort((a, b) => {
            if (a.id > b.id) {
              return -1;
            }

            if (b.id > a.id) {
              return 1;
            }

            return 0;
          });
          this.bracketList = sortedBrackets;
          this.tournament = sortedBrackets[0];
          this.teamList = teams;
          this._validTournament = !!this.tournament && this.tournament.matchupMapping.length > 0;

          this._setupData();
        }
      );
    } else {
      this._validTournament = !!this.tournament && this.tournament.matchupMapping.length > 0;
      this.isLoading = false;
      this._setupData();
    }

  }

  public ngOnChanges(): void {
    this._setupData();
  }

  public ngAfterContentInit(): void {
    this.onResize();
  }

  public updateBracket(event): void {
    const targetBracketIndex = parseInt(event.target.value, 10);
    this.tournament = this.bracketList[targetBracketIndex];
    this._setupData();
  }

  public incrementIndex() {
    if (this.currentRoundIndex < this.indexMax) {
      this.currentRoundIndex++;
      this.bracketArray = this._renderBrackets();
    }
  }

  public decrementIndex() {
    if (this.currentRoundIndex > 0) {
      this.currentRoundIndex--;
      this.bracketArray = this._renderBrackets();
    }
  }

  private _findIndexMax(bracketData: GenericLeagueBracket[]): number {
    if (!!bracketData && bracketData?.length > 0) {
      const bracketsSortByRound = bracketData.sort(({ round: roundA = 0 }, { round: roundB = 0 }) => {
        if(roundA === roundB){
          return 0;
        }

        return roundA > roundB ? -1 : 1;
      });
      // TODO: Do a thing for play-in rounds.
      return bracketsSortByRound[0].round;
    }
    return 0;
  }

  private _setupData() {
    if (this._validTournament) {
      this.maxRounds = this._findIndexMax(this.tournament.matchupMapping);
      this.bracketArray = this._renderBrackets();
    }
  }

  private _bracketMapper(
    bracketData: GenericLeagueBracket[]
  ): Array<GenericLeagueBracket[]> {
    if (bracketData.length > 0) {
      const bracketMapArray = [];
      for (let i = this.maxRounds; i > 0; i--) {
        const currentRound = bracketData.filter(
          (bracketGame) => bracketGame.round === i
        );
        const nextRound = bracketMapArray[0] || [];

        if (nextRound.length > 0) {
          const orderedRoundGames = [];
          nextRound.forEach((game) => {
            const { teamOneSeriesId, teamTwoSeriesId } = game;

            const matchTeamOne = currentRound.find((slotAGame) =>
              teamOneSeriesId ?
                slotAGame.seriesMatchupId === teamOneSeriesId :
                false
            );
            const matchTeamTwo = currentRound.find((slotBGame) =>
              teamTwoSeriesId ?
                slotBGame.seriesMatchupId === teamTwoSeriesId :
                false
            );

            if (matchTeamOne) {
              orderedRoundGames.push(matchTeamOne);
            } else {
              orderedRoundGames.push(EMPTY_PLAYIN_GAME);
            }

            if (matchTeamTwo) {
              orderedRoundGames.push(matchTeamTwo);
            } else {
              orderedRoundGames.push(EMPTY_PLAYIN_GAME);
            }
          });
          bracketMapArray.unshift(orderedRoundGames);
        } else {
          bracketMapArray.unshift(
            bracketData.filter((bracketGame) => bracketGame.round === i)
          );
        }
      }
      return bracketMapArray;
    }

    return [];
  }

  private _mapTeamNames = (
    bracketGame: GenericLeagueBracket
  ): GenericLeagueBracket => {
    if (this.teamList) {
      const teamOneMatch = this.teamList.find(
        (team) => team.id === bracketGame.teamOne
      );
      const teamTwoMatch = this.teamList.find(
        (team) => team.id === bracketGame.teamTwo
      );

      return {
        ...bracketGame,
        teamOneTitle: teamOneMatch ? teamOneMatch.title : "TBD",
        teamTwoTitle: teamTwoMatch ? teamTwoMatch.title : "TBD",
      };
    }

    return bracketGame;
  };

  private _renderBrackets(): GenericLeagueBracket[][] {
    this.indexMax = this.maxRounds - this.columnSize;
    // TODO: We should only render this one per dataset--this currently renders
    // 3 times on page load, and each resize.  We should map the whole bracket once on data consumption
    return this._bracketMapper(this.tournament.matchupMapping)
      .slice(this.currentRoundIndex, this.currentRoundIndex + this.columnSize)
      .map((round) =>
        round.map((roundMatch) => this._mapTeamNames(roundMatch))
      );
  }
}
