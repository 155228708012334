<app-page [invert]="true">
  <div
    class="c_leaguePage"
    *ngIf="
      isPageLoading === false && (pageHasError$ | async) === false;
      else loading
    "
  >
    <app-season-pass-league-banner
      *ngIf="leagueRequiresPasses && (leagueIsOpen$ | async)"
    >
    </app-season-pass-league-banner>
    <div
      class="c_leaguePage_scrollBtns"
      *ngIf="isQuickPlayLeague && (isUserLoggedIn$ | async)"
    >
      <button class="c_button primary_dark" (click)="scrollToElement(bracket)">
        Go to Live Bracket
      </button>
      <button
        *ngIf="userIsInLeague$ | async"
        class="c_button secondary_dark"
        (click)="scrollToElement(chatAnchor)"
      >
        Go to Chat
      </button>
    </div>
    <app-league-feature
      class="c_leagueDetailPage__feature"
    ></app-league-feature>
    <app-league-checkin-panel
      *ngIf="(userIsInLeague$ | async) && (leagueHasQueues$ | async)"
    >
    </app-league-checkin-panel>
    <app-league-details></app-league-details>
    <app-quickplay-checkin-panel
      *ngIf="
        isQuickPlayLeague &&
        (userIsInLeague$ | async) &&
        (leagueHasTournaments$ | async)
      "
    ></app-quickplay-checkin-panel>
    <span #bracket></span>
    <app-league-matches-brackets-container
      *ngIf="!isQuickPlayLeague && (isUserLoggedIn$ | async)"
    >
    </app-league-matches-brackets-container>
    <div
      class="c_leaguePage__addOutline"
      *ngIf="(isUserLoggedIn$ | async) && isQuickPlayLeague"
    >
      <app-bracket-match-block
        [useStreamingBracketData]="isQuickPlayLeague"
      ></app-bracket-match-block>
    </div>
    <span #chatAnchor></span>
    <div
      class="c_leagueDetailPage__chat"
      *ngIf="
        (isUserLoggedIn$ | async) &&
        isQuickPlayLeague &&
        (leagueHasTournaments$ | async) &&
        (userIsInLeague$ | async)
      "
    >
      <app-league-tournament-chat-block></app-league-tournament-chat-block>
    </div>
  </div>
  <ng-template #loading>
    <ng-container *ngIf="pageHasError$ | async; else spinner">
      <div class="c_leagueFeature">
        <h2 class="u_txt--hdg3 u_txt--white">
          Error loading league, please check your URL and try again.
        </h2>
      </div>
    </ng-container>
    <ng-template #spinner>
      <div class="c_leagueFeature">
        <app-loader>Loading League...</app-loader>
      </div>
    </ng-template>
  </ng-template>
</app-page>
