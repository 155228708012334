import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

import { teamRoutes } from "src/app/enums/routes/routePaths";
import { RegistrationTeamInfo } from "src/app/reducers/leagues/league.types";
import { TeamTypes } from "src/app/reducers/teams/teams.types";
import { BaseCVA } from "src/app/components/base-cva/base-cva.class";
import { ImageTypes, processImage } from "src/app/util/image-utils";
import { Unsubscriber } from "src/app/util/unsubscriber";

@Component({
  selector: "app-select-team-step",
  templateUrl: "./select-team-step.component.html",
  styleUrls: ["./select-team-step.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectTeamStepComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectTeamStepComponent),
      multi: true,
    },
  ],
})
export class SelectTeamStepComponent extends BaseCVA
  implements OnInit, ControlValueAccessor, Validators {
  @Input() public regInfoTeams: RegistrationTeamInfo[];
  @Output() public closeDialogue: EventEmitter<boolean> = new EventEmitter<boolean>();

  public teamList: RegistrationTeamInfo[] = [];
  public teamFilterInput$ = new Subject<string>();
  public routes = teamRoutes;

  private _unsub = new Unsubscriber();

  constructor(private _formBuilder: FormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this._formBuilder.group({
      selectedTeam: [null, Validators.required],
    });

    this.teamList = this.regInfoTeams.filter(
      (teams) => teams.teamType === TeamTypes.STANDARD
    );

    this.teamFilterInput$
      .pipe(debounceTime(200), takeUntil(this._unsub.unsubEvent))
      .subscribe((filterTerm) => {
        if (filterTerm) {
          const filterTermNocase = filterTerm.toUpperCase();
          this.teamList = this.regInfoTeams.filter(
            (team) => team.title.toUpperCase().indexOf(filterTermNocase) !== -1
          );
        } else {
          this.teamList = this.regInfoTeams;
        }
      });
  }
  /**
   * Checks to see if a given team is the one currently selected in the radio
   *
   * @param teamId is the id of the team
   * @author Christian Tweed
   */
  public isTeamSelected(teamId: string): boolean {
    const team = this.controlForm.get("selectedTeam").value;
    if (team) {
      return team.id === teamId;
    }
    return false;
  }

  public close(): void {
    this.closeDialogue.emit(true);
  }

  public displayTeamImage(logo: string): string {
    return processImage(logo, ImageTypes.TEAM_SQ);
  }

  public get errorMessage(): string {
    return this.controlForm.invalid ? "No team selected" : "";
  }

  /* Validator Functions */

  public validate(): ValidationErrors | null {
    return this.controlForm.valid ?
      null :
      {
        invalidForm: {
          valid: false,
          message: "No team selected",
        },
      };
  }
}
