import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  combineLatest,
  Observable,
  of,
} from "rxjs";
import { Store } from "@ngrx/store";
import {
  filter,
  map,
  pluck,
  take,
  takeUntil,
  takeWhile,
} from "rxjs/operators";
import { Title } from "@angular/platform-browser";

import { RootState } from "src/app/reducers";
import { GetLeagueById } from "src/app/reducers/leagues/league.actions";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import { Unsubscriber } from "@utils/unsubscriber";
import { SeasonPassService } from "@services/v2/season-pass/season-pass.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { LeaguePassActivationModalComponent } from "src/app/components/league-pass-activation-modal/league-pass-activation-modal.component";
import { LeagueTypes } from "src/app/enums/league-type.enum";
import { Logger } from "@utils/logger";
import { isBefore, parseISO } from "date-fns";

@Component({
  selector: "app-league-detail-page",
  templateUrl: "./league-detail-page.component.html",
  styleUrls: ["./league-detail-page.component.scss"],
})
export class LeagueDetailPageComponent implements OnDestroy, OnInit {
  public isPageLoading = true;
  public pageHasError$ = of(false);
  public leagueRequiresPasses = false;
  public leagueHasTournaments$: Observable<boolean> = of(false);
  public isQuickPlayLeague: boolean | null = null;
  public userIsInLeague$: Observable<boolean> = of(false);
  public isUserLoggedIn$: Observable<boolean> = of(false);
  public leagueHasQueues$: Observable<boolean> = of(false);
  public leagueIsOpen$: Observable<boolean> = of(false);

  private _unsub = new Unsubscriber();

  constructor(
    private _store: Store<RootState>,
    private _route: ActivatedRoute,
    private _titleService: Title,
    private _seasonPassService: SeasonPassService,
    private _modalService: NgxSmartModalService
  ) {
    //TODO: Update the new feature component to show the non logged in button
    this.isUserLoggedIn$ = this._store.select("user", "currentUser").pipe(
      map(user => !!user),
      takeUntil(this._unsub.unsubEvent)
    );

    this._route.paramMap.pipe(take(1)).subscribe((params) => {
      this._store.dispatch(
        new GetLeagueById(+params.get(ComponentRouteParams.LEAGUE_ID))
      );
    });
    this._store.select("leagues", "fetchingLeagueById").pipe(
      takeWhile(() => this.isPageLoading === true)
    ).subscribe(
      (res) => {
        this.isPageLoading = res;
      }
    );
    this.pageHasError$ = this._store.select("leagues", "fetchingLeagueByIdError").pipe(
      takeUntil(this._unsub.unsubEvent)
    );
    this.leagueHasTournaments$ = this._store.select("leagues", "league").pipe(
      pluck("brackets"),
      map((brackets) => {
        if (brackets && brackets.length > 0) {
          return true;
        }

        return false;
      }),
      takeUntil(this._unsub.unsubEvent)
    );

    this.leagueIsOpen$ = this._store.select("leagues", "league").pipe(
      pluck("registrationEndDate"),
      map((registrationEndDate) => {
        try {
          const parsedDate = parseISO(registrationEndDate);
          const isBeforeCurrent = isBefore(parsedDate, new Date());
          return !isBeforeCurrent;
        } catch (e) {
          Logger.error(e);
          return false;
        }
      }),
      takeUntil(this._unsub.unsubEvent)
    );

    this._store.select("leagues", "league").pipe(
      pluck("organizationSeasonPassIds"),
      map((passIds = []) => {
        if (passIds.length > 0) {
          return true;
        }

        return false;
      }),
      takeWhile(() => this.leagueRequiresPasses === false)
    ).subscribe(
      (passIsRequired) => {
        this.leagueRequiresPasses = passIsRequired;
      }
    );

    this.userIsInLeague$ = combineLatest(
      [this._store.select("leagues"), this._store.select("user", "currentUser")]
    )
      .pipe(
        map(([leagueData, userData]) => {
          if (userData.isAdmin) {
            return true;
          }

          const enrollmentInfo = leagueData.leagueEnrollmentStatus;
          const currentId = leagueData.league?.id || -1;
          if (enrollmentInfo && enrollmentInfo.id === currentId) {
            return enrollmentInfo.userIsInLeague;
          }

          return false;
        }),
        takeUntil(this._unsub.unsubEvent)
      );

    this.leagueHasQueues$ = this._store.select("leagues", "league").pipe(
      pluck("queueBrackets"),
      map((qBrackets) => {
        if (qBrackets?.length === 0) {
          return false;
        }

        return true;
      }),
      takeUntil(this._unsub.unsubEvent)
    );
  }

  public ngOnInit(): void {
    this._titleService.setTitle("GGLeagues | League Details");

    this._store.select("leagues", "league").pipe(
      filter((league) => !!league),
      pluck("leagueType"),
      takeWhile(() => this.isQuickPlayLeague === null)
    ).subscribe(
      (leagueType) => {
        if (leagueType === LeagueTypes.QUICKPLAY) {
          this.isQuickPlayLeague = true;
        } else {
          this.isQuickPlayLeague = false;
        }
      }
    );

    LeaguePassActivationModalComponent.initPassActivationModal(
      this._modalService,
      this._route,
      this._seasonPassService,
      this._store
    );
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public scrollToElement($element: Element): void {
    $element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
