<div class="c_stepperContainer">
  <form [formGroup]="controlForm" class="c_stepperContainer__form">
    <div>Choose the team you want to register for the league</div>
    <input
      class="c_input c_selectTeam__teamFilter"
      type="text"
      placeholder="Filter team by name"
      (keyup)="teamFilterInput$.next($event.target.value)"
      *ngIf="teamList.length > 0"
    />
    <div
      class="c_selectTeam__teamListContainer"
      *ngIf="teamList.length > 0; else noTeams"
    >
      <div *ngFor="let team of teamList">
        <input
          id="team_{{ team.id }}"
          class="c_selectTeam__radioInput c_selectTeam__teamSelectInput"
          type="radio"
          [value]="team"
          formControlName="selectedTeam"
          [attr.disabled]="team.isEligible ? null : true"
        />
        <label
          for="team_{{ team.id }}"
          class="c_selectTeam__teamSelectLabel c_selectTeam__label"
          [ngClass]="{ c_selectTeam__selectedRadio: isTeamSelected(team.id) }"
          [attr.disabled]="team.isEligible ? null : true"
        >
          <div
            class="c_selectTeam__team"
            [ngClass]="{ c_selectTeam__selectedRadio: isTeamSelected(team.id) }"
          >
            <div
              class="c_selectTeam__team__icon"
              [ngStyle]="{ 'background-image': displayTeamImage(team.logoUrl) }"
            ></div>
            <div class="c_selectTeam__teamDetails">
              <span class="u_txt c_selectTeam__teamTitle">{{
                team.title
              }}</span>
              <span class="u_txt c_selectTeam__teamPlayers"
                >({{ team.playerCount }} Players)</span
              >
              <span
                class="u_txt c_selectTeam__disabledMessage"
                *ngIf="!team.isEligible"
              >
                {{ team.ineligibleReason }}
              </span>
            </div>
          </div>
        </label>
      </div>
    </div>
    <ng-template #noTeams>
      <div class="c_selectTeam__emptyList">
        You are not the captain of any multi-player teams. Please contact the
        captain of the team and ask them to register, or
        <a (click)="close()" routerLink="/{{ routes.TEAM_NEW }}"
          >create a new team</a
        >
        and return back to this page to register.
      </div>
    </ng-template>
  </form>
  <div class="c_stepperContainer__btns">
    <app-modal-stepper-footer-buttons
      [hasNext]="true"
      [hasPrevious]="false"
      [formToCheck]="controlForm"
      [errorMessage]="errorMessage"
    >
    </app-modal-stepper-footer-buttons>
  </div>
</div>
