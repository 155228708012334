import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "@environments/environment";

import {
  OrganizationTypes,
  LeagueRegistrationInformation,
  JoinLeagueReferralParams,
  LeagueSearchTerms,
  SearchLeague,
  SearchLeagueQuery,
  EliminationBracketDataAPIResponse,
  GenericLeagueBracket,
  LeagueBracketDetails,
} from "../reducers/leagues/league.types";
import { basicAuthHeader } from "../util/auth-utils";
import { apiToPlatform, apiGamePlatforms } from "../enums/game-platforms.enum";
import {
  readSearchHeaders,
  SearchHeaders,
  setupParams,
} from "../util/search-utils";
import { StatesEnum } from "../enums/states.enum";
import { TeamLeagueJoinable } from "./v2/teams/teams.v2.api.types";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  StreamingAPITournamentCheckinData,
  StreamingTournamentData,
  TournamentCheckin,
} from "@apptypes/streaming-tournament.types";
import { FirestoreCollections, TournamentStreamCollections } from "../enums/firestore.enum";
interface LeagueRegistrationInformationAPIResponse {
  data: {
    attributes: {
      entrants: number;
      maximumTeamSize: number;
      minimumTeamSize: number;
      registrationEndDate: number;
      startTime: number;
      teamFee: string;
      title: string;
      organizations: {
        id: string;
        name: string;
        organizationLeagueType: OrganizationTypes;
      }[];
    };
    id: string;
    type: string;
  };
}

interface SearchLeaguesAPIResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      registrationEndDate: number;
      entrants: number;
      timeZone: string;
      finished: boolean;
      startTime: number;
      title: string;
      description: string;
      esport: string;
      defaultTeamFee: string;
    };
  }[];
}

interface GetTeamLeagueJoinableAPIResponse {
  success: string;
}

interface GetTeamLeagueJoinableAPIResponseError {
  error: {
    errors: string[];
  };
}

@Injectable({
  providedIn: "root",
})
export class LeagueEndpointService {
  constructor(
    private _http: HttpClient,
    private _firestore: AngularFirestore
  ) { }

  public getLeagueRegistrationInformationById(id: string | number): Observable<LeagueRegistrationInformation> {
    const url = `${environment.apiBase}/api/v1/leagues/${id}/registration_data`;
    const headers = basicAuthHeader();
    return this._http.get<LeagueRegistrationInformationAPIResponse>(url, {
      headers,
    }).pipe(map((apiResponse) => this._mapLeagueRegistrationAPIToLeagueRegistration(apiResponse)));
  }

  public getTeamLeagueJoinable(teamId: string, leagueId: string): Observable<TeamLeagueJoinable> {
    const url = `${environment.apiBase}/api/v1/league_teams/joinable`;
    const headers = basicAuthHeader();
    const params = new HttpParams({
      fromObject: {
        ["league_id"]: leagueId,
        ["team_id"]: teamId,
      },
    });
    return this._http.get<GetTeamLeagueJoinableAPIResponse>(url, {
      headers,
      params,
    }).pipe(
      map(() => ({
        joinable: true,
        errors: [],
      })),
      catchError(({ error: { errors } }: GetTeamLeagueJoinableAPIResponseError) => of({
        joinable: false,
        errors: errors ?? ["There was a server error, please try again later"],
      }))
    );
  }

  public searchLeagues(searchTerms: LeagueSearchTerms): Observable<SearchLeagueQuery> {
    const { params, headers }: SearchHeaders = setupParams(searchTerms);
    const url = `${environment.apiBase}/api/v1/search/leagues`;
    return this._http.get<SearchLeaguesAPIResponse>(url, {
      headers,
      params,
      observe: "response",
    }).pipe(
      map((apiResponse) => {
        const { page, pageLimit, limit, total } = readSearchHeaders(apiResponse.headers);
        return {
          limit,
          page,
          pageLimit,
          total,
          leagues: this._mapSearchLeagueAPIResponseToSearchLeagues(apiResponse.body),
        };
      })
    );
  }

  // No typing needed, returns null or unsuable response
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public joinLeagueWithSponsorCode(teamId: string, leagueId: string, sponsorCode: string, city?: string): Observable<any> {
    const url = `${environment.apiBase}/api/v1/league_teams/create_with_sponsor_code`;
    const headers = basicAuthHeader();
    const payload = {
      league_team: {
        league_id: leagueId,
        team_id: teamId,
        sponsor_code: sponsorCode.trim(),
        registration_locations_attributes: city ? [
          {
            city,
            state: "Oklahoma",
          },
        ] : [],
      },
    };
    return this._http.post(url, payload, {
      headers,
    });
  }


  public joinLeagueWithOptions(params: JoinLeagueReferralParams): Observable<unknown> {
    interface JoinLeagueInfo {
      league_team: {
        team_id: string;
        league_id: string;
        organization_id?: string;
        stripeToken?: string;
        registration_locations_attributes: {
          city: string;
          state: StatesEnum;
        }[];
      };
    }
    const url = `${environment.apiBase}/api/v1/league_teams`;
    const headers = basicAuthHeader();
    const payload: JoinLeagueInfo = {
      league_team: {
        team_id: params.teamId,
        league_id: params.leagueId,
        registration_locations_attributes: params.city ? [
          {
            city: params.city,
            state: StatesEnum.OK,
          },
        ] : [],
      },
    };
    if (params.organizationId) {
      payload.league_team.organization_id = params.organizationId;
    }
    if (params.stripeToken) {
      payload.league_team.stripeToken = params.stripeToken.id;
    }

    return this._http.post(url, payload, {
      headers,
    });
  }


  public getStreamingTournamentData(bracketStreamRef: string): Observable<LeagueBracketDetails> {
    const referenceUrl = `/${FirestoreCollections.TOURNAMENT_STREAMS}/${bracketStreamRef}`;

    return this._firestore.doc<StreamingTournamentData>(referenceUrl).valueChanges().pipe(
      map((tournamentStreamValues) => ({
        id: tournamentStreamValues.tournament_id.toString(),
        type: "tournaments",
        schedulingType: tournamentStreamValues.scheduling_type,
        title: tournamentStreamValues.title,
        status: tournamentStreamValues.status,
        matchupMapping: tournamentStreamValues.matchup_mapping.map((matchup) => this._tournamentMatchupMapper(matchup)),
        lastUpdated: new Date().toISOString(),
      }))
    );
  }

  public getStreamingCheckinData(bracketStreamRef: string): Observable<TournamentCheckin[]> {
    type CheckinData = StreamingAPITournamentCheckinData & {
      documentReferenceId: string;
    };

    const referenceUrl = `/${FirestoreCollections.TOURNAMENT_STREAMS}/${bracketStreamRef}`;

    const mapCheckinData = (
      {
        documentReferenceId,
        team_id,
        tournament_checkin_id,
        user_id,
        esport_credential_snapshot: esportCredentialSnapshot,
      }: CheckinData): TournamentCheckin => (
      {
        teamId: team_id.toString(),
        tournamentCheckinId: tournament_checkin_id.toString(),
        esportCredentialSnapshot,
        documentReferenceId,
        userId: user_id.toString(),
      }
    );

    return this._firestore.doc(referenceUrl).collection<StreamingAPITournamentCheckinData>(
      TournamentStreamCollections.TOURNAMENT_CHECKINS
    ).valueChanges({
      idField: "documentReferenceId",
    }).pipe(
      map((checkinDataArray) => checkinDataArray.map(mapCheckinData))
    );
  }

  private _tournamentMatchupMapper = (tournamentData: EliminationBracketDataAPIResponse): GenericLeagueBracket => ({
    round: tournamentData.round,
    winner: tournamentData.winner,
    teamOne: tournamentData.team_one,
    teamTwo: tournamentData.team_two,
    matchIds: tournamentData.match_ids ? tournamentData.match_ids : null,
    matchMapper: tournamentData.match_mapper,
    seriesMatchupId: tournamentData.series_matchup_id ? tournamentData.series_matchup_id : null,
    playinRoundId: tournamentData.play_in_round_id,
    teamOneSeriesId: tournamentData.team_one_series_id,
    teamTwoSeriesId: tournamentData.team_two_series_id,
  });

  private _mapLeagueRegistrationAPIToLeagueRegistration(
    apiResponse: LeagueRegistrationInformationAPIResponse
  ): LeagueRegistrationInformation {
    return {
      id: apiResponse.data.id,
      startTime: apiResponse.data.attributes.startTime,
      teamFee: apiResponse.data.attributes.teamFee,
      minimumTeamSize: apiResponse.data.attributes.minimumTeamSize,
      maximumTeamSize: apiResponse.data.attributes.maximumTeamSize,
      entrants: apiResponse.data.attributes.entrants,
      title: apiResponse.data.attributes.title,
      organizations: apiResponse.data.attributes.organizations,
      registrationEndDate: apiResponse.data.attributes.registrationEndDate,
      isSeasonPassLeague: false, //TODO: Replace with api info
    };
  }

  private _mapSearchLeagueAPIResponseToSearchLeagues(apiResponse: SearchLeaguesAPIResponse): SearchLeague[] {
    return apiResponse.data.map((apiLeague) => {
      const {
        registrationEndDate,
        entrants,
        timeZone,
        finished,
        startTime,
        title,
        description,
        esport,
        defaultTeamFee,
      } = apiLeague.attributes;

      return {
        id: apiLeague.id,
        type: apiLeague.type,
        registrationEndDate,
        description,
        entrants,
        esport: apiToPlatform(esport as apiGamePlatforms),
        finished,
        startTime,
        timeZone,
        title,
        defaultTeamFee,
      };
    });
  }
}
