import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  interval,
  Observable,
  of,
} from "rxjs";
import {
  filter,
  map,
  pluck,
  skipWhile,
  take,
  takeUntil,
  takeWhile,
} from "rxjs/operators";
import { Store } from "@ngrx/store";

import {
  LeagueBracketDetails,
  LeagueBracketStatus,
  LeagueTeam,
} from "src/app/reducers/leagues/league.types";
import { RootState } from "src/app/reducers";
import { APITournamentTypes } from "src/app/enums/tournamentTypes.enum";
import { LeagueEndpointService } from "@services/league-endpoints.service";
import { Unsubscriber } from "@utils/unsubscriber";
import { UpdateLeagueBrackets } from "src/app/reducers/leagues/league.actions";
import { Logger } from "@utils/logger";
import { LeaguesV2Service } from "@services/v2/leagues/leagues.v2.service";

@Component({
  selector: "app-bracket-match-block",
  templateUrl: "./bracket-match-block.component.html",
  styleUrls: ["./bracket-match-block.component.scss"],
})
export class BracketMatchBlockComponent implements OnDestroy, OnInit {
  @Input() public useStreamingBracketData = false;
  public leagueTournaments$: Observable<LeagueBracketDetails[]>;
  public leagueTeams$: Observable<LeagueTeam[]> = of([]);
  public loading = true;
  public filteredBracket$: Observable<LeagueBracketDetails> = of(null);
  public matchForm: FormGroup;
  public tournamentType$: Observable<APITournamentTypes>;
  public tournamentTypes = APITournamentTypes;

  public animateUpdate = false;

  private _unsub = new Unsubscriber();
  private _streamEstablished = false;
  private _leagueId: number | null = null;

  constructor(
    private _formBuilder: FormBuilder,
    private _store: Store<RootState>,
    private _leagueService: LeagueEndpointService,
    private _leagueV2Service: LeaguesV2Service
  ) {
    this.matchForm = this._formBuilder.group({
      selectedTournament: [null],
    });
  }

  public ngOnInit() {
    if (this.useStreamingBracketData) {
      this._setupQuickplay();
    } else {
      this._setupTournament();
    }

  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  private _setupQuickplay(): void {


    // Fetch & Store the league id for refetching
    this._store.select("leagues", "league").pipe(
      pluck("id"),
      take(1)
    ).subscribe(
      (id) => {
        this._leagueId = id || null;
        this.leagueTeams$ = this._leagueV2Service.getLeagueTeamList(id);
        this._leagueV2Service.getLeagueBrackets(id).subscribe(
          (brackets) => {
            this._store.dispatch(new UpdateLeagueBrackets(brackets));
          }
        );
      }
    );


    // this._leagueV2Service;
    this.leagueTournaments$ = this._store.select("leagues", "league").pipe(
      filter((league) => !!league),
      pluck("brackets"),
      takeUntil(this._unsub.unsubEvent)
    );

    this.leagueTournaments$.pipe(
      skipWhile((brackets) => brackets.length === 0)
    ).subscribe((brackets) => {
      this._streamEstablished = true;
      // ASSUMPTION: All quickplay leagues will *only* have one bracket in them--so we will always take the first one
      if (brackets[0]) {
        this.matchForm.setValue({
          selectedTournament: brackets[0],
        });

        this.filteredBracket$ = brackets[0].streamReferenceId ?
          this._leagueService.getStreamingTournamentData(brackets[0].streamReferenceId) :
          of(brackets[0]);

        this.filteredBracket$.pipe(
          filter((bracket) => !!bracket),
          takeUntil(this._unsub.unsubEvent)
        ).subscribe(
          () => {
            // Controls
            this.animateUpdate = false;
            clearTimeout();
            setTimeout(() => {
              this.animateUpdate = false;
            }, 500);
            this.animateUpdate = true;
          }
        );

        this.tournamentType$ = of(brackets[0].schedulingType);
      }
    });

    const ONE_MINUTE_IN_MILLISECONDS = 60000;

    interval(ONE_MINUTE_IN_MILLISECONDS).pipe(
      takeWhile(() => this._streamEstablished === false)
    ).subscribe((count) => {
      Logger.log(`Number of times polled league: ${count}`);
      if (this._leagueId !== null) {
        this._leagueV2Service.getLeagueBrackets(this._leagueId)
          .subscribe(
            (res) => this._store.dispatch(new UpdateLeagueBrackets(res))
          );
      }
    }, () => { }, () => {
      Logger.log("Finished getting quickplay");
    });
  }

  private _setupTournament(): void {
    this.leagueTournaments$ = this._store.select("leagues", "league").pipe(
      filter((league) => !!league),
      map((league) => {
        let sortedTourneys: LeagueBracketDetails[] = [];
        if (!!league.brackets && league.brackets.length > 0) {
          const inProgressLeagues = league.brackets.filter(
            (bracket) => bracket.status === LeagueBracketStatus.IN_PROGRESS || bracket.status === LeagueBracketStatus.COMPLETED
          );
          const roundRobinsTournies = inProgressLeagues.filter(
            (roundRobinLeague) => roundRobinLeague.schedulingType === APITournamentTypes.ROUND_ROBIN
          );
          const bracketTournies = inProgressLeagues.filter(
            (bracketLeague) => bracketLeague.schedulingType === APITournamentTypes.ELIMINATION
          );
          const battleRoyaleTournies = inProgressLeagues.filter(
            (brLeague) => brLeague.schedulingType === APITournamentTypes.BATTLE_ROYALE
          );
          sortedTourneys = [
            ...bracketTournies,
            ...roundRobinsTournies,
            ...battleRoyaleTournies,
          ];
          this.tournamentType$ = of(sortedTourneys[0]?.schedulingType || undefined);
          this.matchForm.setValue({
            selectedTournament: sortedTourneys[0] || null,
          });
        }
        return sortedTourneys;
      }),
      take(1)
    );

    this.matchForm.get("selectedTournament").valueChanges.subscribe((tournament) => {
      this.filteredBracket$ = of(tournament);
      this.tournamentType$ = of(tournament?.schedulingType || null);
    });
  }
}
