import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  map,
  catchError,
  filter,
  skipWhile,
  pluck,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import {
  combineLatest,
  Observable,
  of,
} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Store } from "@ngrx/store";

import { environment } from "@environments/environment";
import { basicAuthHeader } from "@utils/auth-utils";
import { readSearchHeaders, setupParams } from "@utils/search-utils";
import { isoStringToUnixTime } from "@utils/date-utils";

import {
  Chatroom,
  FirestoreAPIChatMessage,
  FirestoreChatAPIResponse,
  FirestoreChatMessage,
  FirestoreChatroom,
  FireStoreChatRoomAPI,
  UsernameSearchAPIResponse,
  UsernameSearchQuery,
  UsernameSearchTerms,
} from "./chatroom.api.types";
import { ChatResourceTypes, APIChatResourceTypes } from "src/app/enums/chat-resource-types";
import { MatchCommenter, MatchCommenterIDUsernameMap } from "src/app/reducers/matches/matches.types";
import { RootState } from "src/app/reducers";
import { FetchUnknownMatchCommenters } from "src/app/reducers/matches/matches.actions";
import { FirestoreCollections } from "src/app/enums/firestore.enum";
import { ChatMessageComponent } from "src/app/components/chat-message/chat-message.component";

interface ChatroomWithMembers {
  chatroom: FirestoreChatroom;
  chatMembers: MatchCommenterIDUsernameMap;
}


@Injectable({
  providedIn: "root",
})
export class ChatroomService {
  constructor(
    private _firestore: AngularFirestore,
    private _http: HttpClient,
    private _toast: ToastrService,
    private _store: Store<RootState>
  ) { }

  /**
   * Fetch a chatroom from firestore
   *
   * @param docId
   */
  public getChatroomByDocID(docId: string, isTournamentChatroom: boolean = false): Observable<Chatroom> {
    const collection = isTournamentChatroom ? FirestoreCollections.TOURNAMENT_CHATROOMS : FirestoreCollections.CHATROOMS;
    const resourceType = isTournamentChatroom ? ChatResourceTypes.TOURNAMENT : ChatResourceTypes.SERIES_MATCHUP;

    const chatroomDocument = this._firestore.collection(collection).doc<FirestoreChatAPIResponse>(docId);
    const firestoreMessagesCollection$ = chatroomDocument.collection<FirestoreAPIChatMessage>(
      FirestoreCollections.USER_CHATS
    ).valueChanges({
      idField: "documentId",
    });

    const chatroomData$ = chatroomDocument.valueChanges({
      idField: "chatroomId",
    });

    const firestoreChatroom$ = firestoreMessagesCollection$.pipe(
      withLatestFrom(chatroomData$),
      filter(chatroom => !!chatroom),
      map(([messagesCollection, chatroom]) => {
        if (!chatroom.messages && chatroom?.messages?.length === 0 && messagesCollection.length === 0) {
          chatroom.messages = [];
        }

        if (messagesCollection && messagesCollection.length > 0) {
          chatroom.messages = messagesCollection; //Replace the messages if the collection exists
        }
        return chatroom;
      }),
      map(chatroom => this._mapFirestoreChat(chatroom, resourceType))
    );

    return this._mapChatroom(firestoreChatroom$);
  }

  public getQuickPlayLeagueChat(docId: string): Observable<Chatroom> {
    const chatroomDocument = this._firestore.collection(FirestoreCollections.TOURNAMENT_CHATROOMS).doc<FirestoreChatAPIResponse>(docId);

    const firestoreMessagesCollection$ = chatroomDocument.collection<FirestoreAPIChatMessage>(
      FirestoreCollections.USER_CHATS
    ).valueChanges({
      idField: "documentId",
    });

    const chatroomData$ = chatroomDocument.valueChanges({
      idField: "chatroomId",
    });

    const firestoreChatroom$ = firestoreMessagesCollection$.pipe(
      withLatestFrom(chatroomData$),
      filter(chatroom => !!chatroom),
      map(([messagesCollection, chatroom]) => {
        if (messagesCollection && messagesCollection.length > 0) {
          chatroom.messages = messagesCollection; //Replace the messages if the collection exists
        }
        return chatroom;
      }),
      map(chatroom => this._mapFirestoreChat(chatroom, ChatResourceTypes.SERIES_MATCHUP))
    );

    return this._mapChatroom(firestoreChatroom$);
  }

  public getChatroomMessages(id: string, referenceType: ChatResourceTypes): Observable<Chatroom> {
    const collectionName = referenceType === ChatResourceTypes.SCRIMMAGE ?
      "scrimmage-chatrooms" :
      environment.firebaseConfig.chatroomCollection;

    const collectionId = referenceType === ChatResourceTypes.SERIES_MATCHUP ? id : +id;

    const firestoreChatroom$ = this._firestore.collection<FirestoreChatAPIResponse>(
      collectionName, (ref) => ref.where(referenceType, "==", collectionId)
    ).valueChanges({
      idField: "chatroomId",
    }).pipe(
      filter(chatroom => !!chatroom),
      map(chatrooms => this._mapFirestoreChat(chatrooms[0], referenceType))
    );

    return this._mapChatroom(firestoreChatroom$);
  }

  public notifyAdmin(body: string, gameId: string, userId: string) {
    const url = `${environment.apiBase}/api/v1/match_comments/notify_admin`;
    const headers = basicAuthHeader();
    const payload = {
      match_comment: {
        body,
        game_id: gameId,
        user_id: userId,
        resource_type: APIChatResourceTypes.SERIES_MATCHUP,
      },
    };
    return this._http
      .post(url, payload, {
        headers,
      })
      .pipe(
        map((res) => {
          if (res.hasOwnProperty("success")) {
            this._toast.success("Report Sent");
          } else {
            this._toast.error("Report could not be sent");
          }
          return res;
        }),
        catchError(() => {
          this._toast.error("Report could not be sent");
          return of(null);
        })
      );
  }

  public getUsernames(searchTerms: UsernameSearchTerms = {
    user_ids: [],
  }): Observable<UsernameSearchQuery> {
    const url = `${environment.apiBase}/api/v1/search/users`;
    const { params, headers } = setupParams(searchTerms);
    return this._http.get<UsernameSearchAPIResponse>(url, {
      headers,
      params,
      observe: "response",
    }).pipe(map(apiResponse => ({
      ...readSearchHeaders(apiResponse.headers),
      ...this._mapMatchCommenterFromSearch(apiResponse.body),
    })));
  }

  private _mapMatchCommenterFromSearch(apiResponse: UsernameSearchAPIResponse = {
    data: [],
  }): { users: MatchCommenter[] } {
    return {
      users: apiResponse?.data?.map(apiUser => ({
        id: +apiUser.id,
        inGameName: apiUser.attributes.username,
      })) ?? [],
    };
  }

  /**
   * Formats the api data from firestore into the FirestoreChatroom type
   *
   * @param apiResponse
   * @param referenceType
   */
  private _mapFirestoreChat(apiResponse: FireStoreChatRoomAPI, referenceType: ChatResourceTypes): FirestoreChatroom {
    const id = this._readChatReferenceId(apiResponse, referenceType);
    const safeMessages = apiResponse.messages || [];
    const messages: FirestoreChatMessage[] = safeMessages.map((message) => ({
      userId: +message.user_id,
      message: message.body,
      date: this._formatDate(message.date),
      isAdmin: message.is_admin,
      documentId: message?.documentId,
      hiddenById: message.hidden_by_id?.toString(),
      unhiddenById: message.unhidden_by_id?.toString(),
      hideChatMessage: message.hide_chat_message,
    }));
    return {
      id,
      status: apiResponse.status,
      type: "chatroom",
      messages,
      chatroomId: apiResponse.chatroomId,
    };
  }

  private _formatDate(date: string | {
    seconds: number;
    nanoseconds: number;
  }): number {
    if (typeof (date) === "string") {
      //It's an ISO string
      return isoStringToUnixTime(date);
    }
    return date.seconds;
  }

  /**
   * Reads the data from a FirestoreChatroom and formats it into a Chatroom
   * It also resolves any unknown usernames in the messages
   *
   * @param firestoreChatroom$
   */
  private _mapChatroom = (
    firestoreChatroom$: Observable<FirestoreChatroom>
  ): Observable<Chatroom> => {
    const chatMembers$ = this._readChatMemberMapFromStore();

    return combineLatest([firestoreChatroom$, chatMembers$]).pipe(
      map(([chatroom, chatMembers]) => ({
        chatroom,
        chatMembers,
      })),
      tap(this._requestUnknownChatMembers),
      skipWhile(({ chatroom, chatMembers }: ChatroomWithMembers) => this._findAllUnknownUsers(chatroom, chatMembers).length > 0),
      map(this._combineChatroomWithMembers)
    );
  };

  /**
   * Fetches the MatchCommenterIDUsernameMap from the store
   */
  private _readChatMemberMapFromStore = (): Observable<MatchCommenterIDUsernameMap> => this._store.select("matches").pipe(
    filter(state => !state.fetchingMatchCommenters || state.fetchingMatchCommentersError),
    pluck("matchCommenters"),
    filter(chatMembers => !!chatMembers),
    map(chatMembers => new Map(chatMembers))
  );

  /**
   * Check if there is any unknown users and request their usernames
   *
   * @param chatroomWithMembers
   */
  private _requestUnknownChatMembers = ({ chatroom, chatMembers }: ChatroomWithMembers): void => {
    const userIds = this._findAllUnknownUsers(chatroom, chatMembers);
    if (userIds.length > 0) {
      this._store.dispatch(new FetchUnknownMatchCommenters(userIds));
    }
  };

  /**
   * Combines a FirestoreChatroom with a MatchCommenterIDUsernameMap to make a Chatroom
   *
   * @param chatroomWithMembers
   */
  private _combineChatroomWithMembers = ({ chatroom, chatMembers }: ChatroomWithMembers): Chatroom => {
    const matchCommenters = Array.from(chatMembers).map(chatMember => ({
      id: +chatMember[0],
      inGameName: chatMember[1],
    }));

    return {
      chatroom,
      chatMembers: {
        chatMembers: matchCommenters,
        type: "chatMembers",
      },
      type: "chatroom",
    };
  };

  /**
   * Find the list of ids of unknown users in a chatroom
   *
   * @param chatroom
   * @param chatMembers
   */
  private _findAllUnknownUsers = (chatroom: FirestoreChatroom, chatMembers: Map<string, string>): string[] => {
    const unknownUsers = [];
    for (const message of chatroom.messages) {
      if (message.userId === ChatMessageComponent.SYSTEM_MESSAGE_ID) {
        // DO NOTHING
      } else if (!chatMembers.has(message.userId.toString())) {
        unknownUsers.push(message.userId.toString());
      }
    }
    return Array.from(new Set(unknownUsers));
  };

  /**
   * Get the proper ID parameter from the firestore prop
   *
   * @param apiChatroom
   * @param referenceType
   */
  private _readChatReferenceId(apiChatroom: FireStoreChatRoomAPI, referenceType: ChatResourceTypes): string | number {
    switch (referenceType) {
      case ChatResourceTypes.SERIES_MATCHUP:
        return apiChatroom.series_matchup_id;
      case ChatResourceTypes.SCRIMMAGE:
        return apiChatroom.scrimmage_id;
      case ChatResourceTypes.TOURNAMENT:
        return apiChatroom.tournament_id;
      default:
        return "";
    }
  }
}
