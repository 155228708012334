export enum RegistrationTypes {
  REFERRAL = "Referral",
  SPONSOR = "Sponsor",
  NEITHER = "Neither",
}

export enum DisabledMessages {
  TOO_MANY_PLAYERS = "Too many players on this team",
  TOO_FEW_PLAYERS = "Not enough players on this team",
  ALREADY_IN_LEAGUE = "This team is already in the league",
}

export enum LeagueRegistrationDisabledMessages {
  LEAGUE_FULL = "This league is full",
  LEAGUE_STARTED = "The registration period for this league is over",
  NO_VALID_TEAMS = "You are not the captain of any teams eligible to register in this league",
  ALREADY_IN_LEAGUE = "You are already registered to play in this league",
  NEED_SEASON_PASS = "You need a season pass to play in this league",
  ERROR = "This league cannot be joined at this time, please try again later",
}

export enum OrganizationSeasonPassRegistrationType {
  PAYMENT = "payment",
  CODE = "code",
}

export enum OrganizationSeasonPassStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
