import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Inject,
  Optional,
  SkipSelf,
} from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Store } from "@ngrx/store";
import {
  takeUntil,
  catchError,
  first,
  withLatestFrom,
} from "rxjs/operators";
import { of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { differenceInSeconds, parseISO } from "date-fns";

import { ChatroomService } from "@services/chatroom/chatroom.service";
import {
  Chatroom,
  FirestoreChatMessage,
  FirestoreChatroom,
} from "@services/chatroom/chatroom.api.types";
import { CommentService } from "@services/comment/comment.abstract.service";
import { ChatData } from "@apptypes/chat/chat-data.interface";
import { COMMENT_SERVICE } from "@providers/comment-service/comment-service.token";
import { BannedChatError } from "@services/comment/comment.api.types";

import { RootState } from "src/app/reducers";
import { Unsubscriber } from "src/app/util/unsubscriber";
import { ChatMessage, MatchCommenter } from "src/app/reducers/matches/matches.types";
import { PersonalUserProfile, UserProfile } from "src/app/reducers/user/user.types";
import { Logger } from "src/app/util/logger";
import { ChatResourceTypes } from "src/app/enums/chat-resource-types";
import { Teams } from "src/app/enums/team.enum";
import { SessionStorageKeys } from "src/app/enums/session-storage-keys.enum";

export interface ChatBlockTeam {
  id: string | number;
  players: {
    id: string;
  }[];
}

/**
 * A chat box that splits the messages between admin and non-admin messages. In order to use this, you
 * must provide a COMMENT_SERVICE in the parent component to define the context to send messages.
 *
 * @exports
 * @class SplitAdminChatBlockComponent
 */
@Component({
  selector: "app-split-admin-chat-block",
  templateUrl: "./split-admin-chat-block.component.html",
  styleUrls: ["./split-admin-chat-block.component.scss"],
})
export class SplitAdminChatBlockComponent implements OnInit, OnDestroy {
  @Input() public chatData: ChatData | null = null;
  @Input() public teams: ChatBlockTeam[] = [];
  @Input() public lobbyCodes: string[] = [];
  @Input() public isQueue = false;
  public loading = true;
  public adminMessages: ChatMessage[] = [];
  public userMessages: ChatMessage[] = [];
  public user: PersonalUserProfile | UserProfile | null = null;
  public chat: Chatroom;

  private _unsub = new Unsubscriber();

  constructor(
    //Ask the parent component for the provided CommentService
    @Inject(COMMENT_SERVICE) @SkipSelf() @Optional() private _commentService: CommentService | null,
    private _store: Store<RootState>,
    private _chatService: ChatroomService,
    private _toastr: ToastrService
  ) { }

  public ngOnInit() {
    const user$ = this._store.select("user", "currentUser").pipe(
      first()
    );

    if(!this._commentService){
      Logger.error("You must provide a CommentService in the parent component");
      this._toastr.error("Could Not Load the Chatroom, Please Referesh the Page");
      return;
    }

    if(this.chatData === null){
      Logger.error("No ChatData was provided");
      this._toastr.error("Could Not Load the Chatroom, Please Referesh the Page");
      return;
    }

    const useTournamentChat = this.chatData.referenceType === ChatResourceTypes.TOURNAMENT;
    const isDocIDReferenceType = this.chatData.referenceType === ChatResourceTypes.TOURNAMENT ||
        this.chatData.referenceType === ChatResourceTypes.SERIES_MATCHUP;

    const useDocumentChat = isDocIDReferenceType && !!this.chatData.firestoreDocID && this.chatData.firestoreDocID.length > 0;
    const chat$ = useDocumentChat ?
      this._chatService.getChatroomByDocID(this.chatData.firestoreDocID, useTournamentChat) :
      this._chatService.getChatroomMessages(this.chatData.railsID, this.chatData.referenceType);

    chat$.pipe(
      withLatestFrom(user$),
      takeUntil(this._unsub.unsubEvent),
      catchError((err) => {
        this._toastr.error("Could Not Load the Chatroom, Please Refresh the Page");
        Logger.error(err);
        return of(null);
      })
    ).subscribe((chatAndUser) => {
      if (!!chatAndUser) {
        const [chat, user]: [Chatroom, PersonalUserProfile] = chatAndUser;
        this.user = user;
        this.chat = chat;
        this.userMessages = this._mapChatroomMessages(this.chat.chatroom, this.chat.chatMembers.chatMembers).sort(this._sortChatMessage);
        this.adminMessages = this.userMessages.filter((chatMsg) => chatMsg.isAdmin);
      }
      this.loading = false;
    });

  }

  public ngOnDestroy() {
    this._unsub.kill();
  }

  public submitComment(eventText: string): void {
    let timeSinceLastMessage = -1;
    const MIN_SEC_BETWEEN_MESSAGES = 30;
    try {
      const lastBRChat = localStorage.getItem(SessionStorageKeys.BR_CHAT_THROTTLE);
      timeSinceLastMessage = lastBRChat ? differenceInSeconds(parseISO(new Date().toISOString()), parseISO(lastBRChat)) : null;
    } catch (e) {
      Logger.error(e);
      timeSinceLastMessage = null;
    }

    if(timeSinceLastMessage !== null && timeSinceLastMessage <= MIN_SEC_BETWEEN_MESSAGES){
      this._toastr.error("You can only post one message every 30 seconds");
      return;
    }

    try {
      localStorage.setItem(SessionStorageKeys.BR_CHAT_THROTTLE, new Date().toISOString());
    } catch (e) {
      Logger.error(e);
    }

    if(this.user === null || this.chatData === null || this._commentService === null){
      this._toastr.error("Could not post your message"); //Could use a better toast
      return;
    }

    this._commentService
      .postComment(this.chatData, this.user.id.toString(), eventText)
      .subscribe(
        //Success
        () => { },
        //Error
        (err: HttpErrorResponse) => {
          if(err.error?.error === BannedChatError.error){
            this._toastr.error("You have been banned from this chatroom", "Error");
            return;
          }
          this._toastr.error("Could not post message, please try again.", "Error");
        }
      );

  }

  private _mapChatroomMessages(chatroom: FirestoreChatroom, commenters: MatchCommenter[]): ChatMessage[] {
    const chatMessages: ChatMessage[] = [];
    chatroom.messages.map((message) => {
      let username;
      commenters.filter((commenter) => {
        if (commenter.id === message.userId) {
          username = commenter.inGameName;
          return commenter;
        }
      });
      chatMessages.push({
        isAdmin: message.isAdmin,
        userId: message.userId,
        date: message.date,
        message: message.message,
        isHidden: message.hideChatMessage ?? false,
        username,
        isUser: message.userId === this.user.id,
        team: this._findTeamForChatMessage(message),
      });
    });
    return chatMessages;
  }

  /**
   * Find the team that the message belongs to
   *
   * @param message is the message we are examining
   * @returns undefined if the user belongs to neither team, null if the user is an admin, or the enum representing the team
   * @author Christian Tweed
   */
  private _findTeamForChatMessage(message: FirestoreChatMessage): Teams {
    if (message.isAdmin) {
      return null;
    }
    return [Teams.TEAM_ONE, Teams.TEAM_TWO].find((team) => this._teamHasMember(message, team));
  }

  /**
   * Checks to see if a message's user belongs to a certain team.
   *
   * @param message the message we are examining
   * @param teamId is the enum of the team we are comparing against
   * @author Christian Tweed
   */
  private _teamHasMember(message: FirestoreChatMessage, teamId: Teams): boolean {
    return !!this.teams[teamId].players.find((member) => member.id === `${message.userId}`);
  }

  private _sortChatMessage = (chatMessage1: ChatMessage, chatMessage2: ChatMessage): number => {
    if (chatMessage1.date < chatMessage2.date) {
      return -1;
    }
    if (chatMessage1.date > chatMessage2.date) {
      return 1;
    }
    return 0;
  };
}
