/* eslint-disable @typescript-eslint/no-unused-vars */
import { Logger } from "@utils/logger";
import { SessionStorageKeys } from "src/app/enums/session-storage-keys.enum";

// Refernce to Google Analytics
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

export const registerSignupSuccessEvent = (referralUrl: string = "N/A"): void => {
  try {
    gtag("event", "sign_up_success", {
      event_category: "user_sign_up",
      event_label: `success|ref:${referralUrl}`,
      value: referralUrl,
    });
    // const rid = localStorage.getItem(SessionStorageKeys.REDDIT_CAMPAIGN_REF);
    // const twtr = localStorage.getItem(SessionStorageKeys.TWITTER_CAMPAIGN_REF);
    // const fbclid = localStorage.getItem(SessionStorageKeys.FB_CAMPAIGN_REF);
    // const gclid = localStorage.getItem(SessionStorageKeys.GOOGLE_CAMPAIGN_REF);
    gtag("event", "conversion", {
      send_to: "AW-345413332/u1BxCJjJ2PICENSt2qQB",
    });
    // if (fbclid) {
    //   registerFBSignupSuccess(fbclid);
    // } else if (gclid) {
    //   registerGoogleSignupSuccess(gclid);
    // } else if (rid) {
    //   registerRedditSignupSuccess(rid);
    // } else if (twtr) {
    //   registerTwitterSignupSuccess(twtr);
    // }
  } catch (e) {
    Logger.error("Analytics success event failed to fire");
  }
};

export const registerSignupErrorEvent = (referralUrl: string = "N/A", error: unknown): void => {
  try {
    gtag("event", "sign_up_error", {
      event_category: "user_sign_up",
      event_label: `error|ref:${referralUrl}|err:${error}`,
      value: error,
    });
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};

const registerRedditSignupSuccess = (redditId: string): void => {
  try {
    gtag("event", "reddit_conversion", {
      event_category: "user_sign_up",
      event_label: `rid:${redditId}`,
      value: redditId,
    });
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};

const registerTwitterSignupSuccess = (twitterId: string): void => {
  try {
    gtag("event", "twitter_conversion", {
      event_category: "user_sign_up",
      event_label: `twitter:${twitterId}`,
      value: twitterId,
    });
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};


const registerGoogleSignupSuccess = (gclid: string): void => {
  try {
    gtag("event", "google_conversion", {
      event_category: "user_sign_up",
      event_label: `google:${gclid}`,
      value: gclid,
    });
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};


const registerFBSignupSuccess = (fbclid: string): void => {
  try {
    gtag("event", "fb_conversion", {
      event_category: "user_sign_up",
      event_label: `fb:${fbclid}`,
      value: fbclid,
    });
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};

export const registerLoginSuccessEvent = (): void => {
  try {
    gtag("event", "login_success", {
      event_category: "login",
      event_label: "success",
      value: "success",
    });
    Logger.log(
      {
        event_category: "login",
        event_label: "success",
        value: "success",
      }
    );
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};

export const registerLoginErrorEvent = (error: unknown): void => {
  try {
    gtag("event", "login_error", {
      event_category: "login",
      event_label: `error: ${JSON.stringify(error)}`,
      value: `error: ${JSON.stringify(error)}`,
    });
    Logger.log(
      {
        event_category: "login",
        event_label: `error: ${JSON.stringify(error)}`,
        value: `error: ${JSON.stringify(error)}`,
      }
    );
  } catch (e) {
    Logger.error("Analytics event failed to fire");
  }
};
