export enum SessionStorageKeys {
  USER_JWT = "userjwt",
  REDDIT_CAMPAIGN_REF = "rid_ref",
  GOOGLE_CAMPAIGN_REF = "gclid_ref",
  FB_CAMPAIGN_REF = "fbclid_ref",
  TWITTER_CAMPAIGN_REF = "twtr_ref",
  FALL_BANNER_DISMISSED = "fallbanner_dismissed",
  BR_CHAT_THROTTLE = "last_br_chat",
  REAUTH_REDIRECT = "reauth_redirect_url",
  WEEKLY_NEWUSER_REDIRECT = "weekly_newuser_redirect",
}
