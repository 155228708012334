/* eslint-disable max-len */
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AngularFirestore, AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireModule } from "@angular/fire";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTooltipModule } from "@angular/material/tooltip";

import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreRouterConnectingModule, RouterState } from "@ngrx/router-store";
import {
  reducers,
  metaReducers,
  effects,
} from "./reducers";

import { NgxSmartModalModule } from "ngx-smart-modal";
import { ToastrModule } from "ngx-toastr";
import { environment } from "@environments/environment";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { SignUpPageComponent } from "./pages/sign-up-page/sign-up-page.component";
import { AboutPageComponent } from "./pages/about-page/about-page.component";
import { FaqPageComponent } from "./pages/faq-page/faq-page.component";
import { RulesPageComponent } from "./pages/rules-page/rules-page.component";
import { ContactPageComponent } from "./pages/contact-page/contact-page.component";
import { StaticPageComponent } from "./components/static-page/static-page.component";
import { LeagueDetailPageComponent } from "./pages/league-detail-page/league-detail-page.component";
import { TeamsListComponent } from "./components/teams-list/teams-list.component";
import { SmallMatchBlockComponent } from "./components/small-match-block/small-match-block.component";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { TeamPreviewBlockComponent } from "./components/team-preview-block/team-preview-block.component";
import { UserPageComponent } from "./pages/user-page/user-page.component";
import { PlayerInfoBlockComponent } from "./components/player-info-block/player-info-block.component";
import { PlayerTeamBlockComponent } from "./components/player-team-block/player-team-block.component";
import { LeagueHistoryBlockComponent } from "./components/league-history-block/league-history-block.component";
import { TeamDetailPageComponent } from "./pages/team-detail-page/team-detail-page.component";
import { TeamInfoBlockComponent } from "./components/team-info-block/team-info-block.component";
import { TeamRosterBlockComponent } from "./components/team-roster-block/team-roster-block.component";
import { LargeRosterIconComponent } from "./components/large-roster-icon/large-roster-icon.component";
import { TeamStatsBlockComponent } from "./components/team-stats-block/team-stats-block.component";
import { TeamMatchesBlockComponent } from "./components/team-matches-block/team-matches-block.component";
import { TeamMatchRosterDisplayComponent } from "./components/team-match-roster-display/team-match-roster-display.component";
import { MatchPageComponent } from "./pages/match-page/match-page.component";
import { NotfoundPageComponent } from "./pages/notfound-page/notfound-page.component";
import { AddNewCommentBlockComponent } from "./components/add-new-comment-block/add-new-comment-block.component";
import { UserEditPageComponent } from "./pages/user-edit-page/user-edit-page.component";
import { CreateTeamPageComponent } from "./pages/create-team-page/create-team-page.component";
import { EditTeamRosterPageComponent } from "./pages/edit-team-roster-page/edit-team-roster-page.component";
import { TeamRosterEditBlockComponent } from "./components/team-roster-edit-block/team-roster-edit-block.component";
import { TeamInvitesBlockComponent } from "./components/team-invites-block/team-invites-block.component";
import { TeamAllLeaguesComponent } from "./pages/team-all-leagues/team-all-leagues.component";
import { LeagueFeatureComponent } from "./components/league-feature/league-feature.component";
import { LeagueDetailsComponent } from "./components/league-details/league-details.component";
import { MatchesPanelComponent } from "./components/matches-panel/matches-panel.component";
import { MatchesPreviewComponent } from "./components/matches-preview/matches-preview.component";
import { ComingSoonPlaceholderComponent } from "./components/coming-soon-placeholder/coming-soon-placeholder.component";
import { MyTeamsPageComponent } from "./pages/my-teams-page/my-teams-page.component";
import { PaymentsPageComponent } from "./pages/payments-page/payments-page.component";
import { PaymentBlockComponent } from "./components/payment-block/payment-block.component";
import { JoinLeagueButtonComponent } from "./components/join-league-button/join-league-button.component";
import { CheckoutComponent } from "./components/checkout/checkout.component";
import { TeamEditPageComponent } from "./pages/team-edit-page/team-edit-page.component";
import { TeamsSearchPageComponent } from "./pages/teams-search-page/teams-search-page.component";
import { SchedulePageComponent } from "./pages/schedule-page/schedule-page.component";
import { PasswordResetPageComponent } from "./pages/password-reset-page/password-reset-page.component";
import { NoPermissionsPageComponent } from "./pages/no-permissions-page/no-permissions-page.component";
import { CurrencyMaskDirective } from "./util/input-masks/currency-mask.directive";
import { NotificationsPageComponent } from "./pages/notifications-page/notifications-page.component";
import { ChatBlockComponent } from "./components/chat-block/chat-block.component";
import { ChatStreamComponent } from "./components/chat-stream/chat-stream.component";
import { ChatMessageComponent } from "./components/chat-message/chat-message.component";
import { CreateNewPasswordPageComponent } from "./pages/create-new-password-page/create-new-password-page.component";
import { ScrollBottomDirective } from "./util/view-directives/scroll-bottom.directive";
import { PlayoffBracketComponent } from "./components/playoff-bracket/playoff-bracket.component";
import { JoinLeagueModalComponent } from "./components/join-league-modal/join-league-modal.component";
import { IntegratedCheckoutComponent } from "./components/integrated-checkout/integrated-checkout.component";
import { EsportHelpModalComponent } from "./components/esport-help-modal/esport-help-modal.component";
import { RocketLeagueHelpModalComponent } from "./components/esport-help-modal/rocket-league-help-modal/rocket-league-help-modal.component";
import { DotaHelpModalComponent } from "./components/esport-help-modal/dota-help-modal/dota-help-modal.component";
import { LeagueOfLegendsHelpModalComponent } from "./components/esport-help-modal/league-of-legends-help-modal/league-of-legends-help-modal.component";
import { OverwatchHelpModalComponent } from "./components/esport-help-modal/overwatch-help-modal/overwatch-help-modal.component";
import { ValorantHelpModalComponent } from "./components/esport-help-modal/valorant-help-modal/valorant-help-modal.component";
import { GenericHelpModalComponent } from "./components/esport-help-modal/generic-help-modal/generic-help-modal.component";
import { SeriesMatchupPageComponent } from "./pages/series-matchup-page/series-matchup-page.component";
import { LeaguesSearchPageComponent } from "./pages/leagues-search-page/leagues-search-page.component";
import { VideoModalComponent } from "./components/video-modal/video-modal.component";
import { VideoDisplayComponent } from "./components/video-modal/video-display.component";
import { SelfReportModalComponent } from "./components/self-report-modal/self-report-modal.component";
import { SelfReportButtonComponent } from "./components/self-report-modal/self-report-button.component";
import { NewUserPageComponent } from "./pages/new-user-page/new-user-page.component";
import { DisputeResultsButtonComponent } from "./components/dispute-results-button/dispute-results-button.component";
import { FifaHelpModalComponent } from "./components/esport-help-modal/fifa-help-modal/fifa-help-modal.component";
import { FortniteHelpModalComponent } from "./components/esport-help-modal/fortnite-help-modal/fortnite-help-modal.component";
import { MaddenHelpModalComponent } from "./components/esport-help-modal/madden-help-modal/madden-help-modal.component";
import { NhlHelpModalComponent } from "./components/esport-help-modal/nhl-help-modal/nhl-help-modal.component";
import { LobbyHelpModalFrameComponent } from "./components/esport-help-modal/lobby-help-modal-frame/lobby-help-modal-frame.component";
import { TeamMagicLinkComponent } from "./components/team-magic-link/team-magic-link.component";
import { TeamInstantInviteComponent } from "./pages/team-instant-invite/team-instant-invite.component";
import { GuardianVerificationFormComponent } from "./components/guardian-verification-form/guardian-verification-form.component";
import { BracketMatchBlockComponent } from "./components/bracket-match-block/bracket-match-block.component";
import { BracketControllerComponent } from "./components/bracket-controller/bracket-controller.component";
import { OrganizationSearchPageComponent } from "./pages/organization-search-page/organization-search-page.component";
import { AccountTeamExplainerModalComponent } from "./components/account-team-explainer-modal/account-team-explainer-modal.component";
import { NeedHelpButtonComponent } from "./components/need-help-button/need-help-button.component";
import { CheckoutStepComponent } from "./components/checkout-step/checkout-step.component";
import { SelectTeamStepComponent } from "./components/select-team-step/select-team-step.component";
import { JoinLeagueConfirmationStepComponent } from "./components/join-league-confirmation-step/join-league-confirmation-step.component";
import { RegistrationSelectionStepComponent } from "./components/registration-selection-step/registration-selection-step.component";
import { ReferralChoiceStepComponent } from "./components/referral-choice-step/referral-choice-step.component";
import { CitySelectionStepComponent } from "./components/city-selection-step/city-selection-step.component";
import { SponserCodeStepComponent } from "./components/sponser-code-step/sponser-code-step.component";
import { ContactAdminButtonComponent } from "./components/contact-admin-button/contact-admin-button.component";
import { ContactAdminModalComponent } from "./components/contact-admin-modal/contact-admin-modal.component";
import { DisputeResultsModalComponent } from "./components/dispute-results-modal/dispute-results-modal.component";
import { HeaderToggleButtonComponent } from "./components/header-toggle-button/header-toggle-button.component";
import { RemovePlayerModalComponent } from "./components/remove-player-modal/remove-player-modal.component";
import { LeaveTeamModalComponent } from "./components/leave-team-modal/leave-team-modal.component";
import { ModalStepperFooterButtonsComponent } from "./components/modal-stepper-footer-buttons/modal-stepper-footer-buttons.component";
import { FormFooterErrorMessageComponent } from "./components/form-footer-error-message/form-footer-error-message.component";
import { FormLoadingButtonComponent } from "./components/form-loading-button/form-loading-button.component";
import { GameCredentialsComponent } from "./components/game-credentials/game-credentials.component";
import { GameCredentialsLayoutComponent } from "./components/game-credentials-layout/game-credentials-layout.component";
import { GameCredentialModalComponent } from "./components/game-credential-modal/game-credential-modal.component";
import { ParentsComponent } from "./pages/parents/parents.component";
import { DashNextGameComponent } from "./components/dash-next-game/dash-next-game.component";
import { DashLeagueOverviewComponent } from "./components/dash-league-overview/dash-league-overview.component";
import { DashClubPanelComponent } from "./components/dash-club-panel/dash-club-panel.component";
import { DashClubInfoModalComponent } from "./components/dash-club-info-modal/dash-club-info-modal.component";
import { InfoModalButtonComponent } from "./components/info-modal-button/info-modal-button.component";
import { DashLeaguesInfoModalComponent } from "./components/dash-leagues-info-modal/dash-leagues-info-modal.component";
import { TeamBlockComponent } from "./components/team-block/team-block.component";
import { MatchupRosterTablesComponent } from "./components/matchup-roster-tables/matchup-roster-tables.component";
import { MatchupLobbyBlockComponent } from "./components/matchup-lobby-block/matchup-lobby-block.component";
import { AddFriendsModalComponent } from "./components/add-friends-modal/add-friends-modal.component";
import { AddFriendButtonComponent } from "./components/add-friend-button/add-friend-button.component";
import { DashOverdueInfoModalComponent } from "./components/dash-overdue-info-modal/dash-overdue-info-modal.component";
import { DashMatchPreviewComponent } from "./components/dash-match-preview/dash-match-preview.component";
import { NewMessagesBannerComponent } from "./components/new-messages-banner/new-messages-banner.component";
import { ScrimmagePageComponent } from "./pages/scrimmage/scrimmage-page.component";
import { SeriesMatchupNoCredInfoModalComponent } from "./components/series-matchup-no-cred-info-modal/series-matchup-no-cred-info-modal.component";
import { BattleRoyalePreviewBlockComponent } from "./components/battle-royale-preview-block/battle-royale-preview-block.component";
import { BattleRoyaleLobbyInfoComponent } from "./components/battle-royale-lobby-info/battle-royale-lobby-info.component";
import { BattleRoyaleCheckinModalComponent } from "./components/battle-royale-checkin-modal/battle-royale-checkin-modal.component";
import { SplitAdminChatBlockComponent } from "./components/split-admin-chat-block/split-admin-chat-block.component";
import { FortniteBattleRoyaleHelpModalComponent } from "./components/fortnite-battle-royale-help-modal/fortnite-battle-royale-help-modal.component";
import { BattleRoyaleMatchesPanelComponent } from "./components/battle-royale-matches-panel/battle-royale-matches-panel.component";
import { SmallBattleRoyaleMatchBlockComponent } from "./components/small-battle-royale-match-block/small-battle-royale-match-block.component";
import { BattleRoyaleMatchesPreviewComponent } from "./components/battle-royale-matches-preview/battle-royale-matches-preview.component";
import { BattleRoyaleResultsModalComponent } from "./components/battle-royale-results-modal/battle-royale-results-modal.component";
import { PassDetailsPageComponent } from "./pages/pass-details-page/pass-details-page.component";
import { PassDetailsPanelComponent } from "./components/pass-details-panel/pass-details-panel.component";
import { PassDetailsModalComponent } from "./components/pass-details-modal/pass-details-modal.component";
import { LeaguePassesPageComponent } from "./pages/league-passes-page/league-passes-page.component";
import { LeaguePassesInfoBlockComponent } from "./components/league-passes-info-block/league-passes-info-block.component";
import { LeaguePassResultComponent } from "./components/league-pass-result/league-pass-result.component";
import { LeaguePassListComponent } from "./components/league-pass-list/league-pass-list.component";
import { ProfilePassesPanelComponent } from "./components/profile-passes-panel/profile-passes-panel.component";
import { PassPurchaseModalComponent } from "./components/pass-purchase-modal/pass-purchase-modal.component";
import { SponsorPassModalComponent } from "./components/sponsor-pass-modal/sponsor-pass-modal.component";
import { MailtoPipe } from "./pipes/mailto/mailto.pipe";
import { SeasonPassLeagueBannerComponent } from "./components/season-pass-league-banner/season-pass-league-banner.component";
import { LeaguePassesRouterPipe } from "./pipes/routing-pipes/league-passes-router/league-passes-router.pipe";
import { OrganizationRegistrationStepsComponent } from "./components/organization-registration-steps/organization-registration-steps.component";
import { LeagueContextQueryPipe } from "./pipes/routing-pipes/query-pipes/league-context-query/league-context-query.pipe";
import { SeasonPassLeagueCountContextComponent } from "./components/season-pass-league-count-context/season-pass-league-count-context.component";
import { LeaguePassActivationModalComponent } from "./components/league-pass-activation-modal/league-pass-activation-modal.component";
import { SvgAnimatedCheckComponent } from "./components/svg-animated-check/svg-animated-check.component";
import { BounceLoaderComponent } from "./components/bounce-loader/bounce-loader.component";
import { RulesPdfPageComponent } from "./pages/rules-pdf-page/rules-pdf-page.component";
import { BattleRoyaleAggregateResultsModalComponent } from "./components/battle-royale-aggregate-results-modal/battle-royale-aggregate-results-modal.component";
import { BattleRoyaleNeedHelpModalComponent } from "./components/battle-royale-need-help-modal/battle-royale-need-help-modal.component";
import { NumberOnlyDirective } from "./util/input-masks/number-only.directive";
import { RocketLeagueTournamentHelpModalComponentComponent } from "./components/rocket-league-tournament-help-modal-component/rocket-league-tournament-help-modal-component.component";
import { LeagueTournamentChatBlockComponent } from "./components/league-tournament-chat-block/league-tournament-chat-block.component";
import { TeamListModalComponent } from "./components/team-list-modal/team-list-modal.component";
import { OrganizationSearchMenuComponent } from "./components/organization-search-menu/organization-search-menu.component";
import { EsportsPassInfoModalComponent } from "./components/esports-pass-info-modal/esports-pass-info-modal.component";
import { GlobalAnnouncementBannerComponent } from "./components/global-announcement-banner/global-announcement-banner.component";
import { QuickplayCheckinPanelComponent } from "./components/quickplay-checkin-panel/quickplay-checkin-panel.component";
import { ParticipantListModalComponent } from "./components/participant-list-modal/participant-list-modal.component";
import { TeamDetailRouterPipe } from "./pipes/routing-pipes/team-detail-router/team-detail-router.pipe";
import { TeamLogoDefaultPipe } from "./pipes/image-pipes/team-logo-default/team-logo-default.pipe";
import { LeagueMatchesBracketsContainerComponent } from "./components/league-matches-brackets-container/league-matches-brackets-container.component";
import { LeagueMatchesBlockComponent } from "./components/league-matches-block/league-matches-block.component";
import { DateBoxComponent } from "./components/date-box/date-box.component";
import { LeagueMatchBlockComponent } from "./components/league-match-block/league-match-block.component";
import { EnumTitlePipe } from "./pipes/util-pipes/enumTitle/enum-title.pipe";
import { LeagueTeamStandingsComponent } from "./components/league-team-standings/league-team-standings.component";
import { SeriesMatchupRouterPipe } from "./pipes/routing-pipes/series-matchup-router/series-matchup-router.pipe";
import { ClubSearchPageComponent } from "./pages/club-search-page/club-search-page.component";
import { PreviewCardComponent } from "./components/preview-card/preview-card.component";
import { ClubSearchPreviewCardsComponent } from "./components/club-search-preview-cards/club-search-preview-cards.component";
import { ClubSearchHeroImageComponent } from "./components/club-search-hero-image/club-search-hero-image.component";
import { TextNotificationLoginModalComponent } from "./components/text-notification-login-modal/text-notification-login-modal.component";
import { NotificationManagementPanelComponent } from "./components/notification-management-panel/notification-management-panel.component";
import { PhoneNumberDirective } from "./directives/input-directives/phone-number/phone-number.directive";
import { TextNotificationVerifyModalComponent } from "./components/text-notification-verify-modal/text-notification-verify-modal.component";
import { ErrorInterceptor } from "./guards/error-interceptor.service";
import { LeagueCheckinPageComponent } from "./pages/league-checkin-page/league-checkin-page.component";
import { LeaveQueueGuard } from "./guards/leave-queue-guard.guard";
import { BeginQueueModalComponent } from "./components/begin-queue-modal/begin-queue-modal.component";
import { LeagueCheckinPanelComponent } from "./components/league-checkin-panel/league-checkin-panel.component";
import { SubmissionChoiceStepComponent } from "./components/submission-choice-step/submission-choice-step.component";
import { ReportResultsStepComponent } from "./components/report-results-step/report-results-step.component";
import { ForfeitTeamStepComponent } from "./components/forfeit-team-step/forfeit-team-step.component";
import { ReportConfirmationStepComponent } from "./components/report-confirmation-step/report-confirmation-step.component";
import { ForfeitConfirmationStepComponent } from "./components/forfeit-confirmation-step/forfeit-confirmation-step.component";
import { ExpandableContentComponent } from "./components/expandable-content/expandable-content.component";
import { QueueSuccessModalComponent } from "./components/queue-success-modal/queue-success-modal.component";
import { QueueNoMatchModalComponent } from "./components/queue-no-match-modal/queue-no-match-modal.component";
import { QueueTournamentChatBlockComponent } from "./components/queue-tournament-chat-block/queue-tournament-chat-block.component";
import { DashNextQueueComponent } from "./components/dash-next-queue/dash-next-queue.component";
import { SharedModule } from "./shared/shared.module";
import { MatchHasResultsModalComponent } from "./components/match-has-results-modal/match-has-results-modal.component";
import { UserEditAddressBlockComponent } from "./components/user-edit-address-block/user-edit-address-block.component";
import { NewUserDashboardComponent } from "./components/new-user-dashboard/new-user-dashboard.component";
import { LeagueCarouselFeatureComponent } from "./components/league-carousel-feature/league-carousel-feature.component";
import { SponsorCampaignBarV1Component } from "./components/sponsor-campaign-bar-v1/sponsor-campaign-bar-v1.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginPageComponent,
    SignUpPageComponent,
    AboutPageComponent,
    FaqPageComponent,
    RulesPageComponent,
    ContactPageComponent,
    StaticPageComponent,
    LeagueDetailPageComponent,
    TeamsListComponent,
    SmallMatchBlockComponent,
    DashboardPageComponent,
    TeamPreviewBlockComponent,
    UserPageComponent,
    PlayerInfoBlockComponent,
    PlayerTeamBlockComponent,
    LeagueHistoryBlockComponent,
    TeamDetailPageComponent,
    TeamInfoBlockComponent,
    TeamRosterBlockComponent,
    LargeRosterIconComponent,
    TeamStatsBlockComponent,
    TeamMatchesBlockComponent,
    TeamMatchRosterDisplayComponent,
    MatchPageComponent,
    NotfoundPageComponent,
    AddNewCommentBlockComponent,
    UserEditPageComponent,
    CreateTeamPageComponent,
    EditTeamRosterPageComponent,
    TeamRosterEditBlockComponent,
    TeamInvitesBlockComponent,
    TeamAllLeaguesComponent,
    LeagueFeatureComponent,
    LeagueDetailsComponent,
    MatchesPanelComponent,
    MatchesPreviewComponent,
    ComingSoonPlaceholderComponent,
    MyTeamsPageComponent,
    PaymentsPageComponent,
    PaymentBlockComponent,
    JoinLeagueButtonComponent,
    CheckoutComponent,
    TeamEditPageComponent,
    TeamsSearchPageComponent,
    SchedulePageComponent,
    PasswordResetPageComponent,
    NoPermissionsPageComponent,
    CurrencyMaskDirective,
    NotificationsPageComponent,
    ChatBlockComponent,
    ChatStreamComponent,
    ChatMessageComponent,
    CreateNewPasswordPageComponent,
    ScrollBottomDirective,
    PlayoffBracketComponent,
    JoinLeagueModalComponent,
    IntegratedCheckoutComponent,
    EsportHelpModalComponent,
    RocketLeagueHelpModalComponent,
    DotaHelpModalComponent,
    LeagueOfLegendsHelpModalComponent,
    OverwatchHelpModalComponent,
    ValorantHelpModalComponent,
    GenericHelpModalComponent,
    SeriesMatchupPageComponent,
    LeaguesSearchPageComponent,
    VideoModalComponent,
    VideoDisplayComponent,
    SelfReportModalComponent,
    SelfReportButtonComponent,
    NewUserPageComponent,
    DisputeResultsButtonComponent,
    FifaHelpModalComponent,
    FortniteHelpModalComponent,
    MaddenHelpModalComponent,
    NhlHelpModalComponent,
    LobbyHelpModalFrameComponent,
    TeamMagicLinkComponent,
    TeamInstantInviteComponent,
    GuardianVerificationFormComponent,
    BracketMatchBlockComponent,
    BracketControllerComponent,
    OrganizationSearchPageComponent,
    AccountTeamExplainerModalComponent,
    NeedHelpButtonComponent,
    CheckoutStepComponent,
    SelectTeamStepComponent,
    JoinLeagueConfirmationStepComponent,
    RegistrationSelectionStepComponent,
    ReferralChoiceStepComponent,
    CitySelectionStepComponent,
    SponserCodeStepComponent,
    ContactAdminButtonComponent,
    ContactAdminModalComponent,
    DisputeResultsModalComponent,
    HeaderToggleButtonComponent,
    RemovePlayerModalComponent,
    LeaveTeamModalComponent,
    ModalStepperFooterButtonsComponent,
    FormFooterErrorMessageComponent,
    FormLoadingButtonComponent,
    GameCredentialsComponent,
    GameCredentialsLayoutComponent,
    GameCredentialModalComponent,
    ParentsComponent,
    DashNextGameComponent,
    DashLeagueOverviewComponent,
    DashClubPanelComponent,
    DashClubInfoModalComponent,
    InfoModalButtonComponent,
    DashLeaguesInfoModalComponent,
    TeamBlockComponent,
    MatchupRosterTablesComponent,
    MatchupLobbyBlockComponent,
    AddFriendsModalComponent,
    AddFriendButtonComponent,
    DashOverdueInfoModalComponent,
    DashMatchPreviewComponent,
    NewMessagesBannerComponent,
    ScrimmagePageComponent,
    SeriesMatchupNoCredInfoModalComponent,
    BattleRoyalePreviewBlockComponent,
    BattleRoyaleLobbyInfoComponent,
    BattleRoyaleCheckinModalComponent,
    SplitAdminChatBlockComponent,
    FortniteBattleRoyaleHelpModalComponent,
    BattleRoyaleMatchesPanelComponent,
    SmallBattleRoyaleMatchBlockComponent,
    BattleRoyaleMatchesPreviewComponent,
    BattleRoyaleResultsModalComponent,
    PassDetailsPageComponent,
    PassDetailsPanelComponent,
    PassDetailsModalComponent,
    LeaguePassesPageComponent,
    LeaguePassesInfoBlockComponent,
    LeaguePassResultComponent,
    LeaguePassListComponent,
    MailtoPipe,
    ProfilePassesPanelComponent,
    PassPurchaseModalComponent,
    SponsorPassModalComponent,
    SeasonPassLeagueBannerComponent,
    LeaguePassesRouterPipe,
    OrganizationRegistrationStepsComponent,
    LeagueContextQueryPipe,
    SeasonPassLeagueCountContextComponent,
    LeaguePassActivationModalComponent,
    SvgAnimatedCheckComponent,
    BounceLoaderComponent,
    RulesPdfPageComponent,
    BattleRoyaleAggregateResultsModalComponent,
    BattleRoyaleNeedHelpModalComponent,
    NumberOnlyDirective,
    RocketLeagueTournamentHelpModalComponentComponent,
    LeagueTournamentChatBlockComponent,
    TeamListModalComponent,
    OrganizationSearchMenuComponent,
    EsportsPassInfoModalComponent,
    GlobalAnnouncementBannerComponent,
    QuickplayCheckinPanelComponent,
    ParticipantListModalComponent,
    TeamDetailRouterPipe,
    TeamLogoDefaultPipe,
    LeagueMatchesBracketsContainerComponent,
    LeagueMatchesBlockComponent,
    DateBoxComponent,
    LeagueMatchBlockComponent,
    EnumTitlePipe,
    LeagueTeamStandingsComponent,
    SeriesMatchupRouterPipe,
    ClubSearchPageComponent,
    PreviewCardComponent,
    ClubSearchPreviewCardsComponent,
    ClubSearchHeroImageComponent,
    TextNotificationLoginModalComponent,
    NotificationManagementPanelComponent,
    PhoneNumberDirective,
    TextNotificationVerifyModalComponent,
    LeagueCheckinPageComponent,
    BeginQueueModalComponent,
    LeagueCheckinPanelComponent,
    SubmissionChoiceStepComponent,
    ReportResultsStepComponent,
    ForfeitTeamStepComponent,
    ReportConfirmationStepComponent,
    ForfeitConfirmationStepComponent,
    ExpandableContentComponent,
    QueueSuccessModalComponent,
    QueueNoMatchModalComponent,
    QueueTournamentChatBlockComponent,
    DashNextQueueComponent,
    MatchHasResultsModalComponent,
    UserEditAddressBlockComponent,
    NewUserDashboardComponent,
    LeagueCarouselFeatureComponent,
    SponsorCampaignBarV1Component,
  ],
  imports: [
    // ANGULAR IMPORTS
    BrowserModule.withServerTransition({
      appId: "serverApp",
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    //ANGULAR MATERIAL IMPORTS
    MatStepperModule,
    MatTooltipModule,
    // NGRX IMPORTS
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),
    EffectsModule.forRoot(effects),
    // VENDOR IMPORTS
    NgxSmartModalModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
    }),
    // Firestore Imports
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    SharedModule,
  ],
  providers: [
    AngularFirestore,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    LeaveQueueGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
