<ng-container *ngIf="streamingData$ | async as tournament">
  <section class="c_quickPlayCheckinPanel">
    <header>
      <h3 class="u_txt--hdg3">Check-In</h3>
    </header>
    <dl class="c_quickPlayCheckinPanel__infoList">
      <dt>Status:</dt>
      <dd>{{ getStatusDisplayLabel(tournament) }}</dd>
      <dt>Total Teams Checked-In:</dt>
      <dd>
        <span>
          {{ (streamingCheckins$ | async)?.length || 0 }}
        </span>
        <ng-container *ngIf="streamingCheckins$ | async as checkins">
          <a
            *ngIf="league$ | async as league"
            (click)="viewCheckedInTeams(checkins, league)"
            >View Checked In Teams</a
          >
        </ng-container>
      </dd>
      <dt>Your Check-In:</dt>
      <dd>
        <ng-container *ngIf="activeTeam$ | async as activeTeam; else noTeam">
          <ng-container
            *ngIf="
              !isUserCheckedIn(user$ | async, streamingCheckins$ | async);
              else checkinStatus
            "
          >
            <ng-container
              *ngIf="isCheckinEnabled(tournament); else checkinDisabled"
            >
              <button
                class="c_button primary_dark"
                (click)="openCheckinModal(tournament.id, activeTeam.id)"
              >
                Check-in
              </button>
            </ng-container>
            <ng-template #checkinDisabled>
              <button class="c_button primary_dark" disabled>Checkin</button>
              <p>
                <small>
                  {{ getCheckinDisabledMessage(tournament) }}
                </small>
              </p>
            </ng-template>
          </ng-container>
          <ng-template #checkinStatus>
            <p class="c_quickPlayCheckinPanel__checkedInMessage">
              You are checked in!
            </p>
          </ng-template>
        </ng-container>
        <ng-template #noTeam>
          <p>You do not have a team in this league to check-in with</p>
        </ng-template>
      </dd>
    </dl>
    <hr />
    <p>
      <strong>NOTICE:</strong> Players who do not check-in will not be placed
      into the tournament. Anyone who plays without joining will not have their
      games recorded
    </p>
  </section>
</ng-container>
