import { Token } from "@stripe/stripe-js";

import { ISOString } from "@apptypes/date.types";

import { DisabledMessages, RegistrationTypes } from "src/app/enums/registration-types.enum";
import { apiGamePlatforms, gamePlatforms } from "src/app/enums/game-platforms.enum";
import { APITournamentTypes } from "src/app/enums/tournamentTypes.enum";
import { defaultSearchQuery, SearchQuery } from "src/app/util/search-utils";
import { TeamTypes } from "../teams/teams.types";
import { LeagueTypes } from "src/app/enums/league-type.enum";
import { QueueSession } from "@services/v2/leagues/leagues.v2.api.types";

export interface LeagueState {
  league: GenericLeagueDetails | null;
  leagueEnrollmentStatus: LeagueEnrollment | null;
  fetchingAllLeagues: boolean;
  fetchingAllLeaguesError: boolean;
  fetchingLeagueById: boolean;
  fetchingLeagueByIdError: boolean;
}

export enum LeagueBracketStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETED = "complete",
  CANCELLED = "cancelled",
}

export interface LeagueEnrollment {
  id: string | number;
  userIsInLeague: boolean;
  teamId: string | number;
}
export interface LeagueBracketDetails {
  id: string;
  type: "tournaments";
  schedulingType: APITournamentTypes;
  title: string;
  status: LeagueBracketStatus;
  chatReferenceId?: string;
  matchupMapping: GenericLeagueBracket[];
  streamReferenceId?: string;
  lastUpdated?: ISOString;
}

export interface LeagueQueueBracketDetails extends LeagueBracketDetails {
  schedulingType: APITournamentTypes.ROUND_ROBIN_QUEUE;
  streamReferenceId: string;
  queueSessions: QueueSession[];
}

export interface GenericLeagueMatch {
  id: string;
  date?: string;
  winner?: string;
  teams: GenericLeagueTeam[];
  isSeries?: boolean;
}

export interface GenericLeagueTeam {
  id: string;
  name: string;
  wins: number;
  winner?: boolean;
  losses: number;
  image?: string;
  score?: number;
  members?: GenericRosterMember[];
  captain?: GenericRosterMember;
}

export interface GenericActiveRoster {
  members: GenericRosterMember[];
}

export interface GenericRosterMember {
  id: string;
  inGameName: string;
  name?: string;
  image?: string;
  isCaptain?: boolean;
  teamUserId?: number;
  isApproved?: boolean;
  isStarter?: boolean;
}

export interface LeagueTeam {
  id: number;
  type: string;
  title: string;
  description: string;
  wins: number;
  losses: number;
  logoUrl: string;
  teamType: TeamTypes;
  userIds: string[];
}

export interface LeagueRegistrationConfig {
  register_location?: boolean;
  team_registration_type: TeamTypes;
}

export interface GenericLeagueDetails {
  type: string;
  id: number;
  title: string;
  description: string;
  defaultTeamFee?: string;
  finished: boolean;
  startTime: ISOString;
  maxEntrants: number;
  currentEntrants: number;
  leagueType: LeagueTypes;
  esport: gamePlatforms;
  logoUrl?: string;
  registrationEndDate: ISOString;
  organizationSeasonPassIds: string[];
  teams: LeagueTeam[];
  games: LeagueGame[];
  series: LeagueSeriesMatchupV2[];
  brackets: LeagueBracketDetails[];
  queueBrackets: LeagueQueueBracketDetails[];
  registrationConfig: LeagueRegistrationConfig;
  twitchStreamUrl: string | null;
  teamRegistrationType: TeamTypes;
  leagueLayoutManifest: LeagueLayoutManifest;
}

export enum OrganizationTypes {
  REFERRAL = "referral",
  SPONSOR = "sponsor",
}

export interface LeagueRegistrationInformation {
  entrants: number;
  maximumTeamSize: number;
  minimumTeamSize: number;
  registrationEndDate: number;
  startTime: number;
  teamFee: string;
  title: string;
  organizations: RegistrationOrganization[];
  id: string;
  teams?: RegistrationTeamInfo[];
  currentEntrants?: number;
  registrationConfig?: LeagueRegistrationConfig;
  isSeasonPassLeague: boolean;
}

export interface RegistrationOrganization {
  id: string;
  name: string;
  organizationLeagueType: OrganizationTypes;
}

export interface RegistrationTeamInfo {
  captain: string;
  logoUrl: string;
  id: string;
  title: string;
  playerCount: number;
  isEligible: boolean;
  ineligibleReason: DisabledMessages | string; //Predefined errors or api informed errors
  teamType: TeamTypes;
}

export interface JoinLeagueReferralParams {
  teamId: string;
  leagueId: string;
  stripeToken?: Token;
  organizationId?: string;
  city?: string;
}

export interface LeagueSearchTerms {
  title?: string;
  esport?: apiGamePlatforms;
  open?: boolean;
  organization_id?: string;
  organization_type?: OrganizationTypes;
  page?: number;
  limit?: number;
}

export interface SearchLeague {
  registrationEndDate: number;
  description: string;
  entrants: number;
  id: string;
  timeZone: string;
  finished: boolean;
  startTime: number;
  esport: gamePlatforms;
  title: string;
  defaultTeamFee: string;
}

export interface SearchLeagueQuery extends SearchQuery {
  leagues: SearchLeague[];
}

export const failedQuery: SearchLeagueQuery = {
  ...defaultSearchQuery,
  leagues: [],
};

export interface RoundRobinDataAPIResponse {
  round: number;
  team_one: number;
  team_two: number;
}

export interface EliminationBracketDataAPIResponse {
  match_ids?: number[];
  match_mapper: number;
  round: number;
  team_one: number | string;
  team_two: number | string;
  series_matchup_id: string;
  winner: number | null;
  play_in_round_id?: string;
  team_one_series_id?: string;
  team_two_series_id?: string;
}

export interface GenericLeagueBracket {
  round: number;
  winner: number | null;
  teamOne: number | string;
  teamTwo: number | string;
  teamOneTitle?: string;
  teamTwoTitle?: string;
  matchIds?: number[];
  matchMapper: number;
  playinRoundId?: string;
  teamOneSeriesId?: string;
  teamTwoSeriesId?: string;
  seriesMatchupId?: string;
  isPlayin?: boolean;
}

export interface JoinLeagueConfirmationInformation {
  registrationType: RegistrationTypes;
  referrall: RegistrationOrganization;
  leagueFee: number;
  selectedTeam: RegistrationTeamInfo;
  sponsorCode: string;
  leagueId: string;
  teamType: TeamTypes;
  soloTeamId: string;
  city: string;
  registerWithLocation: boolean;
  isSeasonPassLeague: boolean;
}

export interface JoinLeagueErrorState {
  sponsor: string;
  payment: string;
  team: string;
  referral: string;
  seasonPass: string;
}

export interface LeagueSeriesMatchup {
  id: string;
  type: string;
  gameStartTime: ISOString;
  winner: string | null;
  winnerId: string | null;
  tournamentId: number;
  seriesType?: APITournamentTypes;
  teams: LeagueSeriesMatchupTeam[];
}

export interface LeagueGame {
  id: string;
  type: string;
  teams: {
    id: string;
    title: string;
  }[];
  gameStartTime: ISOString;
  winner: string;
}

export interface LeagueSeriesMatchupTeam {
  id: string;
  teamType?: TeamTypes;
  title: string;
}

export interface LeagueSeriesMatchupTournament {
  id: string;
  type: "tournament";
  schedulingType: APITournamentTypes;
  title: string;
}

export interface LeagueSeriesMatchupV2 {
  id: string;
  type: "seriesMatchups";
  gameStartTime: ISOString;
  winner: string | null;
  winnerId: string | null;
  teams: LeagueSeriesMatchupTeam[];
  tournament: LeagueSeriesMatchupTournament;
  matchupMapping: LeagueSeriesMatchupMatchupMapping | null;
}

export interface LeagueSeriesMatchupMatchupMapping {
  matchupMapper: number;
  round: number;
  teamIds: string[];
  seriesMatchupId: string;
  winnerId: string | null;
}
export interface LeagueTeamStanding {
  id: number;
  title: string;
  gameLosses: number;
  gameWins: number;
  seriesLosses: number;
  seriesWins: number;
}

export interface LeagueLayoutManifest {
  id: string;
  sponsorshipCampaignContentWells: {
    [wellId: string]: {
      assetId: string;
      assetUrl: string;
      assetLink?: string;
    };
  };
}
