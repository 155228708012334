import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ISOString } from "@apptypes/date.types";
import { Store } from "@ngrx/store";
import {
  FinalizedMatchupMapping,
  LeagueQueue,
  LeagueQueueCheckin,
} from "@services/v2/league-queue/league-queue.api.types";
import { LeagueQueueService } from "@services/v2/league-queue/league-queue.service";
import { QueueSessionStatuses } from "@services/v2/leagues/leagues.v2.api.types";
import { padTimeValue } from "@utils/date-utils";
import { modalOptions } from "@utils/modal-helpers";
import { Unsubscriber } from "@utils/unsubscriber";
import {
  addMinutes,
  differenceInSeconds,
  format,
  parseISO,
} from "date-fns";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ToastrService } from "ngx-toastr";
import {
  interval,
  Observable,
  zip,
} from "rxjs";
import {
  map,
  pluck,
  take,
  takeUntil,
} from "rxjs/operators";
import { BeginQueueModalComponent } from "src/app/components/begin-queue-modal/begin-queue-modal.component";
import { QueueNoMatchModalComponent } from "src/app/components/queue-no-match-modal/queue-no-match-modal.component";
import { ComponentRouteParams } from "src/app/enums/routes/routeParams";
import {
  generateLeagueDetailRoute,
  generateSeriesMatchupRoute,
  generateSeriesMatchupRouteFromQueue,
} from "src/app/enums/routes/routePaths";
import { ComponentCanDeactivate } from "src/app/guards/leave-queue-guard.guard";
import { RootState } from "src/app/reducers";
import { LeagueTeam } from "src/app/reducers/leagues/league.types";

@Component({
  selector: "app-league-checkin-page",
  templateUrl: "./league-checkin-page.component.html",
  styleUrls: ["./league-checkin-page.component.scss"],
})
export class LeagueCheckinPageComponent implements ComponentCanDeactivate, OnInit, OnDestroy {
  public userAcceptsQueue: boolean | null = null;
  public queueWaitTime = "00:00";
  public isLoading = true;
  public queueIsStreaming = false;
  public userDidntCheckin = false;
  public checkinStatusInitialized = false;

  private _queueOpenTime: ISOString | null = null;
  private _playerQueueStartTime: null | string = null;
  private _unsub = new Unsubscriber();
  private _leagueId: string = null;
  private _queueId: string = null;
  private _playerId: number;
  private _playerQueueTeamId: number = null;
  private _queueStatus: QueueSessionStatuses;
  private _queueStream$: Observable<LeagueQueue>;
  private _queueTournamentId: string | number = null;
  private _userCheckin$: Observable<LeagueQueueCheckin | null>;
  private _userTeamCheckin$: Observable<boolean>;
  private _finalMatchups: FinalizedMatchupMapping[] = [];
  private _leagueTeams: LeagueTeam[] = [];
  private _roundCount = 1;

  constructor(
    private _modalService: NgxSmartModalService,
    private _leagueQueueService: LeagueQueueService,
    private _store: Store<RootState>,
    private _toastr: ToastrService,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _titleService: Title
  ) { }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload")
  public canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.userAcceptsQueue && !this.matchesFoundForUser) {
      return false;
    }
    return true;
  }

  public async ngOnInit(): Promise<void> {
    this._titleService.setTitle("GGLeagues | League Queue");
    this._leagueId = this._activeRoute.snapshot.paramMap.get(ComponentRouteParams.LEAGUE_ID);
    zip(
      this._store.select("user", "currentUser").pipe(
        pluck("id"),
        take(1)
      ),
      this._store.select("leagues").pipe(
        take(1)
      )

    ).subscribe(
      async ([playerId, leagueState]) => {
        this._playerId = playerId;
        if (leagueState.league && leagueState.league.queueBrackets && leagueState.league.queueBrackets.length > 0) {
          if (leagueState.leagueEnrollmentStatus === null || leagueState.leagueEnrollmentStatus.id.toString() !== this._leagueId) {
            this._rejectQueueAccess();
          } else {
            this._queueId = this._activeRoute.snapshot.paramMap.get(ComponentRouteParams.QUEUE_ID);
            this._playerQueueTeamId = parseInt(`${leagueState.leagueEnrollmentStatus.teamId}`, 10);
            this._queueTournamentId = leagueState.league.queueBrackets[0].id;
            // This is just making this not turn into spaghetti with a random nested service
            const { queueStreamKey, roundCount } = await this._leagueQueueService.getQueuePreflightData(
              this._queueTournamentId,
              this._queueId
            ).toPromise();
            this._roundCount = roundCount;
            this._queueStream$ = this._leagueQueueService.getQueueStream(queueStreamKey);
            this._userCheckin$ = this._leagueQueueService.getUserStreamCheckin(queueStreamKey, this._playerId);
            this._userTeamCheckin$ = this._leagueQueueService.getUserTeamStreamCheckin(queueStreamKey, this._playerQueueTeamId).pipe(
              map((teamCheckin) => !!teamCheckin)
            );
            this._leagueTeams = leagueState.league.teams;
            this._initQueueData();
          }
        } else {
          this._rejectQueueAccess();
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public get backToLeagueRoute(): string {
    return `/${generateLeagueDetailRoute(this._leagueId)}`;
  }

  public get queueCloseTime(): string {
    if (this._queueOpenTime) {
      const parsedQueueStart = parseISO(this._queueOpenTime);
      const STANDARD_QUEUE_LENGTH_MIN = 15;
      const endTime = addMinutes(parsedQueueStart, STANDARD_QUEUE_LENGTH_MIN);
      return format(endTime, "hh:mm a");
    } else {
      return "TBD";
    }
  }

  public get matchesFoundForUser(): boolean {
    if (this._finalMatchups && this._finalMatchups.length > 0) {
      const foundMatches = this.playerMatch;

      if (foundMatches.length > 0) {
        return true;
      }
    }

    return false;
  }

  public get queueTitleMessage(): string {
    if (this.queueIsFinished) {
      return "Queue is Completed";
    } else if (this.matchesFoundForUser) {
      return "Your Match has been Found!";
    } else {
      return "Queueing for Match";
    }
  }

  public get playerMatch(): FinalizedMatchupMapping[] {
    const foundMatch = this._finalMatchups.filter(
      (fMatchup) => (fMatchup.team_one === this._playerQueueTeamId) || (fMatchup.team_two === this._playerQueueTeamId)
    );

    return foundMatch;
  }

  public get queueIsFinished(): boolean {
    return this._queueStatus === QueueSessionStatuses.COMPLETED;
  }

  public get queueStatus(): string {
    if (this._queueStatus === QueueSessionStatuses.COMPLETED) {
      return "The queue has closed, and matches have been assigned";
    }

    return "Queue is in progress.  Waiting for matches...";
  }

  public generateMatchRoute(matchup: FinalizedMatchupMapping): string {
    return `/${generateSeriesMatchupRoute(matchup.series_matchup_id)}`;
  }

  public getTeamNameFromId(teamId: number): string {
    const matchedTeam = this._leagueTeams.find((lTeam) => lTeam.id === teamId);

    if (matchedTeam) {
      return matchedTeam.title;
    } else {
      return "TBD";
    }
  }

  public openQueueModal(): void {
    this.userAcceptsQueue = null;
    this._modalService.create(BeginQueueModalComponent.MODAL_ID, BeginQueueModalComponent, modalOptions).setData({
      setQueueStatus: this._setAcceptanceStatus,
    }).open();
  }

  private _initCheckin(): void {
    zip(
      this._userCheckin$,
      this._userTeamCheckin$
    ).pipe(
      take(1)
    ).subscribe(
      ([userCheckinData, userTeamIsCheckedIn]) => {
        this.checkinStatusInitialized = true;
        if (userCheckinData === null) {
          if (this._queueStatus === QueueSessionStatuses.COMPLETED && !userTeamIsCheckedIn) {
            this.userDidntCheckin = true;
          } else if (this._queueStatus === QueueSessionStatuses.COMPLETED && userTeamIsCheckedIn) {
            // This condition allows members of MP teams to see queue info even if they didn't check in
            this.userAcceptsQueue = true;
          } else {
            this.openQueueModal();
          }

        } else {
          this._playerQueueStartTime = userCheckinData.created_at;
          this.userAcceptsQueue = true;
          this._initTimer(this._playerQueueStartTime);

        }
      }
    );

  }

  private _initQueueData(): void {
    this._queueStream$.pipe(
      take(1)
    ).subscribe(
      (queueData) => {
        this._finalMatchups = queueData.finalized_matchup_mapping;
        this._queueOpenTime = queueData.start_time;
        this._queueStatus = queueData.status;
        if (this._queueStatus === QueueSessionStatuses.COMPLETED) {
          this.queueIsStreaming = false;
          this.userAcceptsQueue = true;
          if (!this.matchesFoundForUser) {
            this._initCheckin();
          }
        } else if (!this.matchesFoundForUser) {
          this.queueIsStreaming = true;
          this._initCheckin();
          this._initQueueStream();
        } else {
          this._initCheckin();
        }
      }
    );
  }

  private _initQueueStream(): void {
    this._queueStream$.pipe(
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(
      (queueData) => {
        this._finalMatchups = queueData.finalized_matchup_mapping;
        this._queueStatus = queueData.status;
        if (this.matchesFoundForUser) {
          this._router.navigate([`/${generateSeriesMatchupRouteFromQueue(this.playerMatch[0].series_matchup_id)}`]);
        } else if (this.queueIsFinished && !this.matchesFoundForUser) {
          this._processNoMatchSequence();
        }
      }
    );
  }

  private _processNoMatchSequence(): void {
    const titleSubKiller = new Unsubscriber();
    let showTitle = false;
    const noMatchModal = this._modalService.create(QueueNoMatchModalComponent.MODAL_NAME, QueueNoMatchModalComponent, modalOptions);
    noMatchModal.onOpenFinished.pipe(
      take(1)
    ).subscribe(
      () => {
        interval(1000).pipe(
          takeUntil(titleSubKiller.unsubEvent)
        ).subscribe(
          () => {
            showTitle = !showTitle;
            if (showTitle) {
              this._titleService.setTitle("GGLeagues | League Queue");
            } else {
              this._titleService.setTitle("**QUEUE CLOSED**");
            }
          }
        );

      }
    );
    noMatchModal.onAnyCloseEvent.pipe(
      take(1)
    ).subscribe(
      () => {
        titleSubKiller.kill();
        this._titleService.setTitle("GGLeagues | League Queue");
      }
    );
    noMatchModal.open();
  }

  private _rejectQueueAccess(): void {
    this._router.navigate([`/${generateLeagueDetailRoute(this._leagueId)}`]);
  }


  private _initTimer = (seedTime?: ISOString): void => {
    if (seedTime) {
      const parsedDate = parseISO(seedTime);
      const bulkSeconds = differenceInSeconds(new Date(), parsedDate);
      const minutes = Math.floor(bulkSeconds / 60);
      const remainderSeconds = bulkSeconds % 60;
      this.queueWaitTime = `${padTimeValue(minutes)}:${padTimeValue(remainderSeconds)}`;
    }
    if (!this.queueIsFinished && !this.matchesFoundForUser) {
      interval(1000).pipe(
        takeUntil(this._unsub.unsubEvent)
      ).subscribe(
        () => {
          const [min, sec] = this.queueWaitTime.split(":");
          const numMin = parseInt(min, 10);
          const numSec = parseInt(sec, 10) + 1;
          if (numSec >= 60) {
            const padMin = padTimeValue(numMin + 1);
            this.queueWaitTime = `${padMin}:00`;
          } else {
            const padMin = padTimeValue(numMin);
            const padSec = padTimeValue(numSec);
            this.queueWaitTime = `${padMin}:${padSec}`;
          }
        }
      );
    }
  };


  // Has to be an arrow function to preserve "this" context
  private _setAcceptanceStatus = (acceptQueueTerms: boolean): void => {
    if (acceptQueueTerms) {
      this._leagueQueueService.checkinToLeagueQueue(this._queueId, this._playerQueueTeamId).subscribe(
        () => {
          this.userAcceptsQueue = acceptQueueTerms;
          this._initTimer();
        },
        (err) => {
          try {
            if (err.errors[0][0] === "User has already been taken") {
              this.userAcceptsQueue = true;
            } else {
              if (err.errors[0][0] === "Cannot checkin early") {
                this._toastr.error("Cannot join queue early");
              } else {
                this._toastr.error("Error joining queue");
              }
            }
          } catch (e) {
            // catchall for if there are any parsing issues
            this._toastr.error("Error joining queue");
            this.userAcceptsQueue = false;
          }
        }
      );
    } else {
      this.userAcceptsQueue = acceptQueueTerms;
    }
  };
}
